import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {AdminSchoolUserService} from '../../../../../../services/admin/admin-school-user.service';
import {UserModel} from '../../../../../../models/authentication/user.model';
import {AdminUserService} from '../../../../../../services/admin/admin-user.service';
import {ErrorModel} from '../../../../../../models/shared/error.model';
import {ModalService} from '../../../../../../shared/modal/modal.service';

@Component({
  selector: 'app-evo-admin-school-teachers-edit-settings',
  templateUrl: './evo-admin-school-teachers-edit-settings.component.html',
  styleUrls: ['./evo-admin-school-teachers-edit-settings.component.css']
})
export class EvoAdminSchoolTeachersEditSettingsComponent implements OnInit {

  schoolId: string;
  userId: string;
  user: UserModel;

  firstName: string;
  lastName: string;

  country: string;
  culture: string;

  accountType: string;
  AccountTypes = UserModel.SchoolAccessTypesDropdown;

  constructor(private route: ActivatedRoute,
              private modalSv: ModalService,
              private adminUserSv: AdminUserService,
              private schoolUserAdminService: AdminSchoolUserService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      this.userId = params.userId;

      if (this.userId) {

        this.schoolUserAdminService.fetchSchoolUserById(this.schoolId,  this.userId).subscribe((user) => {
          this.user = user;
          this.updateInformation();
        });

      }

    });

  }

  updateInformation() {

    this.firstName = this.user.firstName;
    this.lastName = this.user.lastName;
    this.culture = this.user.culture;
    this.country = this.user.country;

    for (const access of this.user.schoolAccess) {
      if (access.schoolId === this.schoolId) {
        this.accountType = access.accessType;
        break;
      }
    }

  }

  hasSchoolId(): Boolean {

    return this.user.findSchoolAccessType(this.schoolId) != null;

  }

  // MARK: User Information

  getUserEmail(): string {

    if (this.user) {
      return this.user.email;
    }

    return '';
  }

  canSaveUserInformation(): boolean {

    if (this.user) {
      if (this.firstName !== this.user.firstName ||
        this.lastName !== this.user.lastName) {
        return true;
      }
    }

    return false;
  }

  onSaveUserInformationClicked() {

    this.user.firstName = this.firstName;
    this.user.lastName = this.lastName;

    this.adminUserSv.updateUser(this.user).subscribe((user) => {

      this.modalSv.showAlertModal('Success', 'The user was updated successfully');
      this.user = user;
      this.updateInformation();

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  // MARK: Country & Culture

  canSaveCultureInformation(): boolean {

    if (this.user) {
      if (this.culture !== this.user.culture ||
        this.country !== this.user.country) {
        return true;
      }
    }

    return false;
  }

  onSaveCultureInformation() {

    this.user.culture = this.culture;
    this.user.country = this.country;

    this.adminUserSv.updateUser(this.user).subscribe((user) => {

      this.modalSv.showAlertModal('Success', 'The culture was updated successfully');
      this.user = user;
      this.updateInformation();

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  // MARK: School Information

  canSaveSchoolInformation() {

    let currentAccountType = null;
    for (const access of this.user.schoolAccess) {
      if (access.schoolId === this.schoolId) {
        currentAccountType = access.accessType;
        break;
      }
    }

    if (this.user) {
      if (this.accountType !== currentAccountType) {
        return true;
      }
    }

    return false;
  }

  onSaveSchoolInformation() {

    for (const access of this.user.schoolAccess) {
      if (access.schoolId === this.schoolId) {
        access.accessType = this.accountType;
      }
    }

    this.adminUserSv.updateUser(this.user).subscribe((user) => {

      this.modalSv.showAlertModal('Success', 'Accountt ype updated successfully');
      this.user = user;
      this.updateInformation();

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    })

  }

}
