import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentFilterModel} from '../../models/filters/student-filter.model';
import {GroupQueryModel} from '../../models/school/group.model';

@Component({
  selector: 'app-evo-student-filter-button',
  templateUrl: './evo-student-filter-button.component.html',
  styleUrls: ['./evo-student-filter-button.component.css']
})
export class EvoStudentFilterButtonComponent implements OnInit {

  @Input() studentFilter: StudentFilterModel | GroupQueryModel;
  @Output() filterChanged = new EventEmitter<StudentFilterModel | GroupQueryModel>();

  isOpen = false;

  constructor() { }

  ngOnInit() {
  }

  onButtonClicked() {
    this.isOpen = !this.isOpen;
  }

  getValue(): number {

    if (this.studentFilter) {
      return this.studentFilter.fromAge;
    }

    return null;

  }

  setValue(value: number) {
    if (this.studentFilter) {
      this.studentFilter.fromAge = value;
      this.studentFilter.toAge = value;
    }
  }

  onClear() {

    this.studentFilter.fromAge = null;
    this.studentFilter.toAge = null;

    this.onEmit();

  }

  onApply() {

    this.onEmit();

  }

  onEmit() {

    this.isOpen = false;

    if (this.filterChanged) {
      this.filterChanged.emit(this.studentFilter);
    }

  }

  numFilters(): number {

    if (this.studentFilter.toAge != null) {
      return 1;
    }

    return 0;
  }

}
