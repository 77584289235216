import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {TranslateService} from '@ngx-translate/core';
import {ObjectiveModel} from '../../../../models/edu-admin/objective.model';
import {SkillGroupModel} from '../../../../models/edu-admin/skillGroup.model';
import {SkillGroupStructureModel} from '../../../../models/student/skillGroupStatusModel';

@Component({
  selector: 'app-group-dash-skills-overview',
  templateUrl: './group-dash-skills-overview.component.html',
  styleUrls: ['./group-dash-skills-overview.component.css']
})
export class GroupDashSkillsOverviewComponent implements OnInit {

  topOffset = '0px';

  @Input() students: StudentModel[] = [];
  @Input() skillGroups: SkillGroupModel[];
  @Input() skillGroupStructure: SkillGroupStructureModel;

  @ViewChild('skillHeader', { static: true }) skillHeader: ElementRef;
  @ViewChild('skillContainer', { static: true }) skillContainer: ElementRef;

  @Input('scrollContainer') scrollContainer: HTMLDivElement;

  @Input('scrollChanged')
  set isScrollChanged(scrollTopOffset: number) {
    this.onScroll();
  }

  @Input() skillsYAxis = false;

  constructor(public tSv: TranslateService) { }

  ngOnInit() {

  }

  getContainerWidth(): string {

    return (this.students.length * 62).toString() + 'px';

  }

  onScroll() {

    if (this.scrollContainer != null) {

      // start location of the scroll container
      const startY = this.scrollContainer.offsetTop;

      // current y location with the scroll
      const currentY = startY + this.scrollContainer.scrollTop;

      // start position to move header
      const yDiff = currentY - this.skillContainer.nativeElement.offsetTop;

      // Need to subtract scroll bar height if any
      const stopHeight = this.skillContainer.nativeElement.offsetHeight - this.skillHeader.nativeElement.offsetHeight;

      if (yDiff >= 0 && yDiff < stopHeight) {

        this.topOffset = (yDiff + 2).toString() + 'px';

      } else if (yDiff < 0) {

        this.topOffset = '0px';

      }

    }

  }

  getSkillGroups(): SkillGroupModel[] {

    if (this.skillGroups) {
      return this.skillGroups;
    }

    return [];
  }

  getColorsForStudentSkillGroupMastery(studentId: string, skillGroupId: string): { 'background-color': string, 'border-width': string, 'border-style': string, 'border-color': string } {

    if (this.skillGroupStructure) {

      const value = this.skillGroupStructure.masteryForStudentSkillGroupStatus(studentId, skillGroupId);

      if (value === -2) {
        // Indicates the student is struggling with a question/skill in the skill group
        return {
          'background-color': 'rgba(215, 33, 33, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(215, 33, 33, 1.0)'
        };

      } else if (value >= 0 && value < 0.5) {

        return {
          'background-color': 'rgba(255, 203, 5, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(255, 203, 5, 1.0)'
        };

      } else if (value >= 0.5 && value < 0.8) {

        return {
          'background-color': 'rgba(121, 197, 112, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(121, 197, 112, 1.0)'
        };

      } else if (value >= 0.8 && value < 1.0) {

        return {
          'background-color': 'rgba(0, 156, 204, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(0, 156, 204, 1.0)'
        };

      } else if (value >= 1.0) {

        return {
          'background-color': 'rgba(155, 89, 182, 0.3)',
          'border-width': '1px',
          'border-style': 'solid',
          'border-color': 'rgba(155, 89, 182, 1.0)'
        };

      }

    }

    // Default gray color
    return {
      'background-color': 'rgba(216, 216, 216, 0.3)',
      'border-width': '1px',
      'border-style': 'solid',
      'border-color': 'rgba(216, 216, 216, 1)'
    };

  }

}
