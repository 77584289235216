import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {SchoolAdminGroupService} from '../../../../../../services/school/school-admin-group.service';
import {ModalService} from '../../../../../../shared/modal/modal.service';
import {StudentModel} from '../../../../../../models/authentication/student.model';
import {GroupQueryModel} from '../../../../../../models/school/group.model';
import {EVOStudentListSort} from '../../../../../../shared/evo-student-list-view/evo-student-list-view.component';
import {forkJoin} from 'rxjs';
import {ErrorModel} from '../../../../../../models/shared/error.model';

@Component({
  selector: 'app-school-admin-group-detail-add-students',
  templateUrl: './school-admin-group-detail-add-students.component.html',
  styleUrls: ['./school-admin-group-detail-add-students.component.css']
})
export class SchoolAdminGroupDetailAddStudentsComponent implements OnInit {

  _show = false;
  @Input('show')
  set show(show: boolean) {
    this._show = show;
    this.fetchStudentsForGroup();
  }

  get show(): boolean {
    return this._show;
  }

  _groupId: string;
  @Input('groupId')
  set groupId(groupId: string) {
    this._groupId = groupId;
    this.fetchStudentsForGroup();
  }

  get groupId(): string {
    return this._groupId;
  }

  hasMoreData = false;
  students: StudentModel[] = [];
  selectedStudents: StudentModel[] = [];
  /**
   * Used when netowkring to disable the button
   */
  addingStudents = false;

  @Output() close = new EventEmitter();
  @Output() studentsAdded = new EventEmitter();

  filter = new GroupQueryModel();

  sort = new EVOStudentListSort();

  constructor(private modalSv: ModalService,
              private tSv: TranslateService,
              private groupSv: SchoolAdminGroupService) { }

  ngOnInit() {
    this.filter.sort = 1;
  }

  fetchStudentsForGroup() {

    if (this._groupId && this._show) {

      this.filter.skip = this.students.length;

      this.groupSv.fetchStudentsWithNoRelationshipToGroup(this.groupId, this.filter).subscribe((students) => {

        this.hasMoreData = students.length === this.filter.limit;

        for (const student of students) {
          if (this.students.filter((s) => s._id === student._id).length === 0) {
            this.students.push(student);
          }
        }

      });

    }

  }

  onFilterChanged(filter: GroupQueryModel) {

    this.filter = filter;
    this.students = [];
    this.fetchStudentsForGroup();

  }

  onSearchStudents(studentName: string) {

    if (studentName === '' || !studentName) {
      this.filter.name = null;
    } else {
      this.filter.name = studentName;
    }

    this.students = [];
    this.selectedStudents = [];

    this.fetchStudentsForGroup();

  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.sort = sort.name;
    this.students = [];
    this.selectedStudents = [];

    this.fetchStudentsForGroup();

  }

  getNumFilters(): string {

    let numFilters = 0;

    if (this.filter.name) {
      numFilters++;
    }

    if (this.filter.fromAge || this.filter.toAge) {
      numFilters++;
    }

    return numFilters.toFixed(0);

  }

  onAddClicked() {

    this.addingStudents = true;
    const studentsToAdd = [];
    for (const student of this.selectedStudents) {
      studentsToAdd.push(this.groupSv.addStudentToGroup(this.groupId, student._id));
    }

    forkJoin(studentsToAdd).subscribe((students) => {

      this.addingStudents = false;
      this.selectedStudents = [];
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));

      if (this.studentsAdded) {
        this.studentsAdded.emit();
      }

    }, (err: ErrorModel) => {
      this.addingStudents = false;
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onCloseClicked() {
    this._show = false;
    if (this.close) {
      this.close.emit();
    }
  }

}
