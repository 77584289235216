import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ModalService} from '../../../../shared/modal/modal.service';
import {AdminPlacementTestService} from '../../../../services/admin/admin-placement-test.service';
import {PlacementTestModel} from '../../../../models/edu-admin/placement-test.model';
import {ErrorModel} from '../../../../models/shared/error.model';

@Component({
  selector: 'app-admin-placement-test-settings',
  templateUrl: './admin-placement-test-settings.component.html',
  styleUrls: ['./admin-placement-test-settings.component.css']
})
export class AdminPlacementTestSettingsComponent implements OnInit {

  placementTestId: string;
  updatingPlacement = false;
  placementTest: PlacementTestModel;

  showPlacementTestCopy = false;
  copiedPlacementTest: PlacementTestModel;

  password = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private placementSv: AdminPlacementTestService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.placementTestId = params.testId;
      this.fetchPlacementTest();

    });

  }

  fetchPlacementTest() {

    this.placementSv.fetchPlacementTestById(this.placementTestId).subscribe((placementTest) => {

      this.placementTest = placementTest;

    });

  }

  onSaveClicked() {

    this.updatingPlacement = true;
    this.placementSv.updatePlacementTest(this.placementTest).subscribe((placement) => {
      this.updatingPlacement = false;
      this.modalSv.showAlertModal('Success', 'Placement test information updated successfully');
    }, (err: ErrorModel) => {
      this.updatingPlacement = false;
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onCopyClicked() {

    this.showPlacementTestCopy = true;
    const json = JSON.parse(JSON.stringify(this.placementTest));
    this.copiedPlacementTest = PlacementTestModel.generateModel(json);

  }

  onCreateNewPlacementTest() {

    this.placementSv.createPlacementTest(this.copiedPlacementTest).subscribe((newPlacement) => {
      this.router.navigate(['admin', 'placementTests', newPlacement._id, 'detail', 'structure'])
    });

  }

  onCreatePlacementTest() {

    this.placementSv.createPlacementTest(this.placementTest).subscribe((newPlacement) => {

      this.router.navigate(['admin', 'placementTests', newPlacement._id, 'detail', 'structure']);

    });

  }

  onArchive(archive: boolean) {

    this.placementSv.archivePlacementTest(this.placementTest._id, this.password, archive).subscribe((placement) => {
      this.placementTest = placement;
      this.password = '';
      this.modalSv.showAlertModal('Success', 'The placement archive status has been successfully changed.');
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

}
