import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing/app-routing.module';
import { AppComponent } from './app.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LandNavHeaderComponent } from './shared/land-nav-header/land-nav-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { ChartsModule } from 'ng2-charts';
import { RootComponent } from './root/root.component';
import { ModalService } from './shared/modal/modal.service';
import { EvoInputComponent } from './shared/evo-input/evo-input.component';
import { ModalComponent } from './shared/modal/modal.component';
import { ForgotPassComponent } from './landing-page/authentication/forgot-pass/forgot-pass.component';
import { VerifyEmailComponent } from './landing-page/authentication/verify-email/verify-email.component';
import { ResetPassComponent } from './landing-page/authentication/reset-pass/reset-pass.component';
import { PlatformRootComponent } from './platform/pages/platform-root/platform-root.component';
import { DashboardComponent } from './platform/pages/dashboard/dashboard.component';
import { PlatformSidebarComponent } from './platform/pages/platform-root/evo-sidebar/platform-sidebar.component';
import { PlatformHeaderComponent } from './platform/pages/platform-root/evo-header/platform-header.component';
import { EmailNotVerifiedComponent } from './landing-page/authentication/email-not-verified/email-not-verified.component';
import { EvoFooterComponent } from './shared/evo-footer/evo-footer.component';
import { AnswersComponent } from './platform/pages/answers/answers.component';
import { EvoDropdownComponent } from './shared/evo-dropdown/evo-dropdown.component';
import { EvoLangDropdownComponent } from './shared/evo-lang-dropdown/evo-lang-dropdown.component';
import { EvoRecentActivityComponent } from './shared/evo-recent-activity/evo-recent-activity.component';
import { AnswerContainerComponent } from './shared/answer-container/answer-container.component';
import { AnswersListComponent } from './shared/answers-list/answers-list.component';
import { EvoInputDateComponent } from './shared/evo-input-date/evo-input-date.component';
import { DashProgressBarComponent } from './platform/pages/dashboard/dash-progress-bar/dash-progress-bar.component';
import { SigninSecondaryComponent } from './landing-page/authentication/signin-secondary/signin-secondary.component';
import { SignupSecondaryComponent } from './landing-page/authentication/signup-secondary/signup-secondary.component';
import { EvoDecorationBackgroundSignupComponent } from './shared/evo-decoration-background-signup/evo-decoration-background-signup.component';
import { CreateStudentComponent } from './landing-page/authentication/create-student/create-student.component';
import { EvoProgressPointsComponent } from './shared/evo-progress-points/evo-progress-points.component';
import { SelectSubscriptionComponent } from './platform/payment-process/select-subscription/select-subscription.component';
import { BillingInformationComponent } from './platform/payment-process/billing-information/billing-information.component';
import { SelectBookComponent } from './platform/payment-process/select-book/select-book.component';
import { SelectBookContainerComponent } from './platform/payment-process/select-book/select-book-container/select-book-container.component';
import { EvoCheckmarkComponent } from './shared/evo-checkmark/evo-checkmark.component';
import { AdminUsersComponent } from './admin/admin-users/admin-users.component';
import { AboutBookComponent } from './platform/payment-process/about-book/about-book.component';
import { AboutEvolytesGameComponent } from './platform/payment-process/about-evolytes-game/about-evolytes-game.component';
import { AboutMonitorComponent } from './platform/payment-process/about-monitor/about-monitor.component';
import { BillingConfirmationComponent } from './platform/payment-process/billing-confirmation/billing-confirmation.component';
import { HamburgerButtonComponent } from './shared/hamburger-button/hamburger-button.component';
import { MobileMenuComponent } from './mobile/mobile-menu/mobile-menu.component';
import { TcComponent } from './landing-page/authentication/tc/tc.component';
import { PrivacyPolicyComponent } from './landing-page/authentication/privacy-policy/privacy-policy.component';
import { EvoAdminRootComponent } from './admin/evo-admin-root/evo-admin-root.component';
import { EvoAdminHeaderComponent } from './admin/evo-admin-header/evo-admin-header.component';
import { EvoAdminQuestionsComponent } from './admin/evo-admin-questions/evo-admin-questions.component';
import { EvoAdminSidebarComponent } from './admin/shared/evo-admin-sidebar/evo-admin-sidebar.component';
import { EvoAdminQEditComponent } from './admin/evo-admin-questions/evo-admin-q-edit/evo-admin-q-edit.component';
import { EvoCountNumbersComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-count-numbers/evo-count-numbers.component';
import {
  EvoCountMultipleNumbersComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-count-multiple-numbers/evo-count-multiple-numbers.component';
import {
  EvoSingleAdditionComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-single-addition/evo-single-addition.component';
import {
  EvoDoubleAdditionComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-double-addition/evo-double-addition.component';
import {
  EvoSingleAdditionNeededAmountComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-single-addition-needed-amount/evo-single-addition-needed-amount.component';
import {
  EvoSingleSubtractionComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-single-subtraction/evo-single-subtraction.component';
import {
  EvoDoubleSubtractionComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-double-subtraction/evo-double-subtraction.component';
import {
  EvoReusableRangeComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-reusable-range/evo-reusable-range.component';
import { EvoTranslatedInputComponent } from './shared/evo-translated-input/evo-translated-input.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EvoLandChooseLanguageComponent } from './landing-page/localization/evo-land-choose-language/evo-land-choose-language.component';
import {
  EvoSizeComparisonNumbersComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-size-comparison-numbers/evo-size-comparison-numbers.component';
import {
  EvoSizeComparisonSymbolsComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-size-comparison-symbols/evo-size-comparison-symbols.component';
import {
  EvoSizeComparisonWordsComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-size-comparison-words/evo-size-comparison-words.component';
import {
  EvoRepeatedAdditionComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-repeated-addition/evo-repeated-addition.component';
import {
  EvoShapeNameFromImageComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-shape-name-from-image/evo-shape-name-from-image.component';
import {
  EvoShapeSingleDiscriminationCountComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-shape-single-discrimination-count/evo-shape-single-discrimination-count.component';
import { EvoShapeCountComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-shape-count/evo-shape-count.component';
import {
  EvoImagePatternSingleFillComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-image-pattern-single-fill/evo-image-pattern-single-fill.component';
import {
  EvoNumberPatternSingleFillComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-number-pattern-single-fill/evo-number-pattern-single-fill.component';
import { EvoListSelectorComponent } from './shared/evo-list-selector/evo-list-selector.component';
import { EvoAdminSchoolsComponent } from './admin/evo-admin-schools/evo-admin-schools.component';
import { EvoAdminSchoolEditComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-edit.component';
import {
  EvoAdminSchoolUserEditComponent
} from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-user-edit.component';
import {
  EvoAdminSchoolStudentEditComponent
} from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-students/evo-admin-school-student-edit/evo-admin-school-student-edit.component';
import {
  EvoAdminUserSelectStudentComponent
} from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-user-select-student/evo-admin-user-select-student.component';
import {
  EvoAdminSchoolUploadUsersComponent
} from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-upload-teachers/evo-admin-school-upload-users.component';
import {
  EvoAdminSchoolUploadStudentsComponent
} from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-students/evo-admin-school-upload-students/evo-admin-school-upload-students.component';
import { EvoUnitHouseComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-unit-house/evo-unit-house.component';
import {
  EvoSingleMultiplicationComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-single-multiplication/evo-single-multiplication.component';
import { AdminSelectCountryComponent } from './admin/shared/admin-select-country/admin-select-country.component';
import { AdminPricingComponent } from './admin/admin-pricing/admin-pricing.component';
import { AdminMonthlyPricingComponent } from './admin/admin-pricing/admin-monthly-pricing/admin-monthly-pricing.component';
import { EvoLandChooseCountryComponent } from './landing-page/localization/evo-land-choose-country/evo-land-choose-country.component';
import { EvoCountryChangeComponent } from './shared/evo-country-change/evo-country-change.component';
import { BookDashboardContainerComponent } from './platform/pages/books/book-dashboard-container/book-dashboard-container.component';
import { AdminBooksComponent } from './admin/admin-books/admin-books.component';
import { AdminBookEditComponent } from './admin/admin-books/admin-book-edit/admin-book-edit.component';
import { AdminBookVersionEditComponent } from './admin/admin-books/admin-book-version-edit/admin-book-version-edit.component';
import { AdminBookIntegrationEditComponent } from './admin/admin-books/admin-book-integration-edit/admin-book-integration-edit.component';
import { QRCodeModule } from 'angularx-qrcode';
// tslint:disable-next-line:max-line-length
import { EvoSingleSubtractionNeededAmountComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-single-subtraction-needed-amount/evo-single-subtraction-needed-amount.component';
import { EvoPaginationComponent } from './shared/evo-pagination/evo-pagination.component';
import { EvoSelectMutlipleChoiceComponent } from './shared/evo-select-mutliple-choice/evo-select-mutliple-choice.component';
import { EvoMultipleSwitchComponent } from './shared/evo-multiple-switch/evo-multiple-switch.component';
import { EvoAutocompleteDropdownComponent } from './shared/evo-autocomplete-dropdown/evo-autocomplete-dropdown.component';
import { PricingComponent } from './landing-page/pages/pricing/pricing.component';
import { LandingComponent } from './landing-page/pages/landing/landing.component';
import { CarouselComponent } from './landing-page/pages/landing/carousel/carousel.component';
import { Land2NavHeaderComponent } from './landing-page/shared/land2-nav-header/land2-nav-header.component';
import { FooterComponent } from './landing-page/shared/footer/footer.component';
import { SchoolsComponent } from './landing-page/pages/schools/schools.component';
import { ShoolsCarouselComponent } from './landing-page/pages/schools/shools-carousel/shools-carousel.component';
import { AboutUsComponent } from './landing-page/pages/about-us/about-us.component';
import { ResearchComponent } from './landing-page/pages/research/research.component';
import { ChangeLangCountryComponent } from './landing-page/shared/change-lang-country/change-lang-country.component';
import { FaqComponent } from './landing-page/pages/faq/faq.component';
import { FeaturesComponent } from './landing-page/pages/features/features.component';
import { GameComponent } from './landing-page/pages/features/game/game.component';
import { BookComponent } from './landing-page/pages/features/book/book.component';
import { MonitorComponent } from './landing-page/pages/features/monitor/monitor.component';
import { ContactUsComponent } from './landing-page/pages/contact-us/contact-us.component';
import { AdminObjectiveComponent } from './admin/admin-objective/admin-objective.component';
import { AdminObjectiveEditComponent } from './admin/admin-objective/admin-objective-edit/admin-objective-edit.component';
import { AdminSkillGroupComponent } from './admin/admin-skill-group/admin-skill-group.component';
import { AdminSkillGroupEditComponent } from './admin/admin-skill-group/admin-skill-group-edit/admin-skill-group-edit.component';
// tslint:disable-next-line:max-line-length
import { AdminUserEditComponent } from './admin/admin-users/admin-user-edit/admin-user-edit.component';
import { AdminPassordValidationComponent } from './admin/admin-users/admin-passord-validation/admin-passord-validation.component';
import { AdminStudentsComponent } from './admin/admin-students/admin-students.component';
import { AdminStudentEditComponent } from './admin/admin-students/admin-student-edit/admin-student-edit.component';
import { AdminUsersSidebarFilterComponent } from './admin/admin-users/admin-users-sidebar-filter/admin-users-sidebar-filter.component';
// tslint:disable-next-line:max-line-length
import { AdminStudentsSidebarFilterComponent } from './admin/admin-students/admin-students-sidebar-filter/admin-students-sidebar-filter.component';
// tslint:disable-next-line:max-line-length
import { EvoAdminSchoolsSidebarFilterComponent } from './admin/evo-admin-schools/evo-admin-schools-sidebar-filter/evo-admin-schools-sidebar-filter.component';
import { FeatureTabComponent } from './landing-page/pages/features/feature-tab/feature-tab.component';
import { TestimonialsComponent } from './landing-page/shared/testimonials/testimonials.component';
import { EasterEggComponent } from './landing-page/shared/easter-egg/easter-egg.component';
import { EggDirective } from './landing-page/shared/egg.directive';
import { SchoolAdminRootComponent } from './platform/school-admin/school-admin-root/school-admin-root.component';
import { SchoolAdminHeaderComponent } from './platform/school-admin/school-admin-root/school-admin-header/school-admin-header.component';
import { SchoolAdminSidebarComponent } from './platform/school-admin/school-admin-root/school-admin-sidebar/school-admin-sidebar.component';
import { SchoolAdminTeachersComponent } from './platform/school-admin/school-admin-teachers/school-admin-teachers.component';
import { SchoolAdminStudentsComponent } from './platform/school-admin/school-admin-students/school-admin-students.component';
import { SchoolAdminStudentsImportComponent } from './platform/school-admin/school-admin-students-import/school-admin-students-import.component';
import { SchoolAdminTeacherDetailComponent } from './platform/school-admin/school-admin-teachers/school-admin-teacher-detail/school-admin-teacher-detail.component';
import { TeacherInvitationSignupComponent } from './platform/invitation-flows/teacher-invitation-signup/teacher-invitation-signup.component';
import { EvoCircleCheckmarkComponent } from './shared/evo-circle-checkmark/evo-circle-checkmark.component';
import { SchoolAdminStudentDetailComponent } from './platform/school-admin/school-admin-students/school-admin-student-detail/school-admin-student-detail.component';
import { BooksComponent } from './platform/pages/books/books/books.component';
import { ChapterContainerComponent } from './shared/chapter-container/chapter-container.component';
import { BookContainerComponent } from './shared/book-container/book-container.component';
import { SchoolAdminGroupsComponent } from './platform/school-admin/school-admin-groups/school-admin-groups.component';
import { SchoolAdminGroupDetailComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail.component';
import { SchoolAdminTeachersInvitationImportComponent } from './platform/school-admin/school-admin-teachers/school-admin-teachers-invitation-import/school-admin-teachers-invitation-import.component';
import { AdminMobileMenuComponent } from './admin/shared/admin-mobile-menu/admin-mobile-menu.component';
import { LayoutModule } from '@angular/cdk/layout';
import { SchoolAdminMobileSidebarComponent } from './platform/school-admin/school-admin-root/school-admin-mobile-sidebar/school-admin-mobile-sidebar.component';
import { EvoAdminSchoolUploadUsersInvitationsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-upload-teachers-invitations/evo-admin-school-upload-users-invitations.component';
import { EvoAdminSchoolUsersInvitationsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-invitations/evo-admin-school-users-invitations.component';
import {
  SchoolAdminTeachersInvitationsComponent
} from './platform/school-admin/school-admin-teachers/school-admin-teachers-invitations/school-admin-teachers-invitations.component';
import { StudentProfileComponent } from './platform/pages/dashboard/student-profile/student-profile.component';
import { ActivityChartsComponent } from './platform/pages/dashboard/activity-charts/activity-charts.component';
import { SkillProgressChartComponent } from './platform/pages/dashboard/skill-progress-chart/skill-progress-chart.component';
import { NewSettingsPageComponent } from './platform/pages/settings/student-settings-page/new-settings-page.component';
import { SettingsBarComponent } from './platform/pages/settings/shared/settings-bar/settings-bar.component';
import { ProfileSettingsComponent } from './platform/pages/settings/shared/profile-settings/profile-settings.component';
import { PasswordSettingsComponent } from './platform/pages/settings/shared/password-settings/password-settings.component';
import { NewBillingSettingsComponent } from './platform/pages/settings/shared/new-billing-settings/new-billing-settings.component';
import { AuthSettingsComponent } from './platform/pages/settings/shared/auth-settings/auth-settings.component';
import { ManageSettingsButtonComponent } from './platform/pages/settings/shared/manage-settings-button/manage-settings-button.component';
import { ToggleSwitchComponent } from './shared/toggle-switch/toggle-switch.component';
import { AccountNameComponent } from './platform/pages/settings/shared/account-name/account-name.component';
import { BookDetailComponent } from './platform/pages/books/book-detail/book-detail.component';
import { BookInfosComponent } from './platform/pages/books/book-infos/book-infos.component';
import { NewChapterComponent } from './shared/new-chapter/new-chapter.component';
import { AttemptContainerComponent } from './platform/pages/books/attempt-container/attempt-container.component';
import { SelectStudentComponent } from './platform/selection/select-student/select-student.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SchoolAdminLicenseComponent } from './platform/school-admin/school-admin-license/school-admin-license.component';
import { DatepickerComponent } from './shared/datepicker/datepicker.component';
import { CircleProgressBarComponent } from './shared/circle-progress-bar/circle-progress-bar.component';
import { UserSettingsModalComponent } from './platform/pages/settings/user-settings-modal/user-settings-modal.component';
import {AccountsSettingsComponent} from './platform/pages/settings/shared/accounts-settings/accounts-settings.component';
import {SettingsStudentProfileComponent} from './platform/pages/settings/shared/settings-student-profile/settings-student-profile.component';
import {EvoDatePickerComponent} from './shared/lm-date-picker/evo-date-picker.component';
import {EvoDatePickerInputComponent} from './shared/evo-date-picker-input/evo-date-picker-input.component';
import { PlatformAnswerFilterComponent } from './platform/pages/platform-root/platform-answer-filter/platform-answer-filter.component';
import { EvoSegmentedControlComponent } from './shared/evo-segmented-control/evo-segmented-control.component';
import { SchoolAdminGroupDetailStudentsComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-detail-students.component';
import { SchoolAdminGroupDetailTeachersComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-teachers/school-admin-group-detail-teachers.component';
import { SchoolAdminGroupDetailSettingsComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-settings/school-admin-group-detail-settings.component';
import { EvoStudentListViewComponent } from './shared/evo-student-list-view/evo-student-list-view.component';
import { SchoolAdminGroupDetailAddStudentsComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-detail-add-students/school-admin-group-detail-add-students.component';
import { EvoTeacherListViewComponent } from './shared/evo-teacher-list-view/evo-teacher-list-view.component';
import { SchoolAdminGroupDetailAddTeachersComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-teachers/school-admin-group-detail-add-teachers/school-admin-group-detail-add-teachers.component';
import { EvoUsersListViewComponent } from './shared/evo-users-list-view/evo-users-list-view.component';
import { EvoSchoolsListViewComponent } from './shared/evo-schools-list-view/evo-schools-list-view.component';
import { EvoGroupsListViewComponent } from './shared/evo-groups-list-view/evo-groups-list-view.component';
import { EvoAdminSchoolStudentsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-students/evo-admin-school-students.component';
import { EvoAdminSchoolTeachersComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers.component';
import { EvoAdminSchoolLicensesComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-licenses/evo-admin-school-licenses.component';
import { EvoAdminSchoolSettingsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-settings/evo-admin-school-settings.component';
import { EvoAdminSchoolTeachersEditSettingsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-teachers-edit-settings/evo-admin-school-teachers-edit-settings.component';
import { EvoAdminSchoolTeachersEditStudentsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-teachers-edit-students/evo-admin-school-teachers-edit-students.component';
import { EvoAdminSchoolTeachersEditGroupsComponent } from './admin/evo-admin-schools/evo-admin-school-edit/evo-admin-school-teachers/evo-admin-school-teachers-edit/evo-admin-school-teachers-edit-groups/evo-admin-school-teachers-edit-groups.component';
import { EvoQuestionListViewComponent } from './shared/evo-question-list-view/evo-question-list-view.component';
import { EvoSingleDivisionComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-single-division/evo-single-division.component';
import { EvoMonthYearPickerComponent } from './shared/evo-month-year-picker/evo-month-year-picker.component';
import { EvoSkillGroupsListViewComponent } from './shared/evo-skill-groups-list-view/evo-skill-groups-list-view.component';
import { AdminSkillGroupQuestionsComponent } from './admin/admin-skill-group/admin-skill-group-edit/admin-skill-group-questions/admin-skill-group-questions.component';
import { AdminSkillGroupSettingsComponent } from './admin/admin-skill-group/admin-skill-group-edit/admin-skill-group-settings/admin-skill-group-settings.component';
import { TeacherLinkInvitationComponent } from './platform/invitation-flows/teacher-link-invitation/teacher-link-invitation.component';
import { SelectSchoolComponent } from './platform/selection/select-school/select-school.component';
import { SchoolAdminStudentMultiDeleteComponent } from './platform/school-admin/school-admin-students/school-admin-student-multi-delete/school-admin-student-multi-delete.component';
import { EvoPassModalComponent } from './shared/evo-pass-modal/evo-pass-modal.component';
import { EvoCornerNameFromImageComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-corner-name-from-image/evo-corner-name-from-image.component';
import { EvoFractionSetupFromImageComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-fraction-setup-from-image/evo-fraction-setup-from-image.component';
import { EvoProportionsSetupFromImageComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-proportions-setup-from-image/evo-proportions-setup-from-image.component';
import { EvoTranslate2dComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-translate2d/evo-translate2d.component';
import { AdminPlacementTestComponent } from './admin/admin-placement-test/admin-placement-test.component';
import { AdminPlacementTestEditComponent } from './admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-edit.component';
import { EvoPlacementTestsListViewComponent } from './shared/evo-placement-tests-list-view/evo-placement-tests-list-view.component';
import { AdminPlacementTestSettingsComponent } from './admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-settings/admin-placement-test-settings.component';
import { AdminPlacementTestStructureComponent } from './admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-structure/admin-placement-test-structure.component';
import { AdminPlacementTestRequisiteSkillsComponent } from './admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-requisite-skills/admin-placement-test-requisite-skills.component';
import { AdminQuestionSelectionListComponent } from './admin/shared/admin-question-selection-list/admin-question-selection-list.component';
import { AdminPlacementTestQuestionContainerComponent } from './admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-structure/shared/admin-placement-test-question-container/admin-placement-test-question-container.component';
import { AdminPlacementTestQuestionModalComponent } from './admin/admin-placement-test/admin-placement-test-edit/admin-placement-test-structure/shared/admin-placement-test-question-modal/admin-placement-test-question-modal.component';
import { EvoObjectiveListViewComponent } from './shared/evo-objective-list-view/evo-objective-list-view.component';
import { AdminObjectiveSettingsComponent } from './admin/admin-objective/admin-objective-edit/admin-objective-settings/admin-objective-settings.component';
import { AdminObjectiveSkillsComponent } from './admin/admin-objective/admin-objective-edit/admin-objective-skills/admin-objective-skills.component';
import { AdminObjectiveSkillGroupsComponent } from './admin/admin-objective/admin-objective-edit/admin-objective-skill-groups/admin-objective-skill-groups.component';
import { AdminPlacementTestSelectionListComponent } from './admin/shared/admin-placement-test-selection-list/admin-placement-test-selection-list.component';
import { EvoAuthenticationFailureModalComponent } from './shared/evo-authentication-failure-modal/evo-authentication-failure-modal.component';
import { EvoCreateGroupModalComponent } from './shared/evo-create-group-modal/evo-create-group-modal.component';
import { SchoolAdminGroupDetailStudentContainerComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-detail-student-container/school-admin-group-detail-student-container.component';
import { SchoolAdminGroupAddExistingStudentComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-add-existing-student/school-admin-group-add-existing-student.component';
import { EvoStudentFilterButtonComponent } from './shared/evo-student-filter-button/evo-student-filter-button.component';
import { SchoolAdminGroupAddNewStudentComponent } from './platform/school-admin/school-admin-groups/school-admin-group-detail/school-admin-group-detail-students/school-admin-group-add-new-student/school-admin-group-add-new-student.component';
import { AdminBookSelectionListComponent } from './admin/shared/admin-book-selection-list/admin-book-selection-list.component';
import { EvoQuestionFilterButtonComponent } from './shared/evo-question-filter-button/evo-question-filter-button.component';
import { AdminObjectiveArchivedComponent } from './admin/admin-objective/admin-objective-archived/admin-objective-archived.component';
import { AdminPlacementTestArchivedComponent } from './admin/admin-placement-test/admin-placement-test-archived/admin-placement-test-archived.component';
import { AdminSchoolSelectionComponent } from './admin/shared/admin-school-selection/admin-school-selection.component';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaModule} from 'ng-recaptcha';
import {environment} from '../environments/environment';
import { AdminUserSettingsComponent } from './admin/admin-users/admin-user-edit/admin-user-settings/admin-user-settings.component';
import { AdminUserStudentsComponent } from './admin/admin-users/admin-user-edit/admin-user-students/admin-user-students.component';
import { GroupRootComponent } from './platform/groups/group-root/group-root.component';
import { GroupDashboardComponent } from './platform/groups/group-dashboard/group-dashboard.component';
import { GroupSidebarComponent } from './platform/groups/group-root/group-sidebar/group-sidebar.component';
import { GroupHeaderComponent } from './platform/groups/group-root/group-header/group-header.component';
import { GroupDashObjectiveComponent } from './platform/groups/group-dashboard/group-dash-objective/group-dash-objective.component';
import { GroupDashActivityComponent } from './platform/groups/group-dashboard/group-dash-activity/group-dash-activity.component';
import { GroupDashSkillsOverviewComponent } from './platform/groups/group-dashboard/group-dash-skills-overview/group-dash-skills-overview.component';
import { AdminSkillGroupsSelectionListComponent } from './admin/shared/admin-skill-groups-selection-list/admin-skill-groups-selection-list.component';
import { AppLinkComponent } from './landing-page/deep-linking/app-link/app-link.component';
import { GroupStudentsComponent } from './platform/groups/group-students/group-students.component';
import { GroupDashProblemAreasComponent } from './platform/groups/group-dashboard/group-dash-problem-areas/group-dash-problem-areas.component';
import { GroupDashStudentSessionsComponent } from './platform/groups/group-dashboard/group-dash-student-sessions/group-dash-student-sessions.component';
import {
  EvoPerimeterFromShapeComponent
} from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-perimeter-from-shape/evo-perimeter-from-shape.component';
import { EvoAreaFromShapeComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-area-from-shape/evo-area-from-shape.component';
import { EvoRoundingWholeNumbersComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-rounding-whole-numbers/evo-rounding-whole-numbers.component';
import { EvoReusableImageTypeComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-reusable-image-type/evo-reusable-image-type.component';
import { EvoReusableMultipleImageTypesComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-reusable-multiple-image-types/evo-reusable-multiple-image-types.component';
import { EvoBarChartComponent } from './admin/evo-admin-questions/evo-admin-q-edit/models/evo-bar-chart/evo-bar-chart.component';

@NgModule({
  declarations: [
    AppComponent,
    PricingComponent,
    LandNavHeaderComponent,
    RootComponent,
    EvoInputComponent,
    ModalComponent,
    ForgotPassComponent,
    VerifyEmailComponent,
    ResetPassComponent,
    PlatformRootComponent,
    DashboardComponent,
    PlatformSidebarComponent,
    PlatformHeaderComponent,
    EmailNotVerifiedComponent,
    EvoFooterComponent,
    AnswersComponent,
    EvoDropdownComponent,
    EvoLangDropdownComponent,
    EvoRecentActivityComponent,
    AnswerContainerComponent,
    AnswersListComponent,
    EvoInputDateComponent,
    DashProgressBarComponent,
    SigninSecondaryComponent,
    SignupSecondaryComponent,
    EvoDecorationBackgroundSignupComponent,
    CreateStudentComponent,
    EvoProgressPointsComponent,
    SelectSubscriptionComponent,
    BillingInformationComponent,
    SelectBookComponent,
    SelectBookContainerComponent,
    EvoCheckmarkComponent,
    AdminUsersComponent,
    AboutBookComponent,
    AboutEvolytesGameComponent,
    AboutMonitorComponent,
    BillingConfirmationComponent,
    HamburgerButtonComponent,
    MobileMenuComponent,
    TcComponent,
    PrivacyPolicyComponent,
    EvoAdminSidebarComponent,
    AdminMobileMenuComponent,
    EvoAdminRootComponent,
    EvoAdminHeaderComponent,
    EvoAdminQuestionsComponent,
    EvoAdminQEditComponent,
    EvoCountNumbersComponent,
    EvoCountMultipleNumbersComponent,
    EvoSingleAdditionComponent,
    EvoDoubleAdditionComponent,
    EvoSingleAdditionNeededAmountComponent,
    EvoSingleSubtractionComponent,
    EvoDoubleSubtractionComponent,
    EvoReusableRangeComponent,
    EvoTranslatedInputComponent,
    EvoLandChooseLanguageComponent,
    EvoSizeComparisonNumbersComponent,
    EvoSizeComparisonSymbolsComponent,
    EvoSizeComparisonWordsComponent,
    EvoRepeatedAdditionComponent,
    EvoShapeNameFromImageComponent,
    EvoShapeSingleDiscriminationCountComponent,
    EvoShapeCountComponent,
    EvoImagePatternSingleFillComponent,
    EvoNumberPatternSingleFillComponent,
    EvoListSelectorComponent,
    EvoAdminSchoolsComponent,
    EvoAdminSchoolEditComponent,
    EvoAdminSchoolUserEditComponent,
    EvoAdminSchoolStudentEditComponent,
    EvoAdminUserSelectStudentComponent,
    EvoAdminSchoolUploadUsersComponent,
    EvoAdminSchoolUploadStudentsComponent,
    EvoUnitHouseComponent,
    EvoSingleMultiplicationComponent,
    AdminSelectCountryComponent,
    AdminPricingComponent,
    AdminMonthlyPricingComponent,
    EvoLandChooseCountryComponent,
    EvoCountryChangeComponent,
    BookDashboardContainerComponent,
    AdminBooksComponent,
    AdminBookEditComponent,
    AdminBookVersionEditComponent,
    AdminBookIntegrationEditComponent,
    EvoSingleSubtractionNeededAmountComponent,
    AdminUserEditComponent,
    AdminPassordValidationComponent,
    AdminStudentsComponent,
    AdminStudentEditComponent,
    EvoPaginationComponent,
    EvoSelectMutlipleChoiceComponent,
    EvoMultipleSwitchComponent,
    EvoAutocompleteDropdownComponent,
    PricingComponent,
    LandingComponent,
    CarouselComponent,
    Land2NavHeaderComponent,
    FooterComponent,
    SchoolsComponent,
    ShoolsCarouselComponent,
    AboutUsComponent,
    ResearchComponent,
    ChangeLangCountryComponent,
    FaqComponent,
    FeaturesComponent,
    GameComponent,
    BookComponent,
    MonitorComponent,
    ContactUsComponent,
    AdminSkillGroupComponent,
    AdminSkillGroupEditComponent,
    AdminObjectiveComponent,
    AdminObjectiveEditComponent,
    AdminUsersSidebarFilterComponent,
    AdminStudentsSidebarFilterComponent,
    EvoAdminSchoolsSidebarFilterComponent,
    FeatureTabComponent,
    TestimonialsComponent,
    EasterEggComponent,
    EggDirective,
    SchoolAdminRootComponent,
    SchoolAdminHeaderComponent,
    SchoolAdminSidebarComponent,
    SchoolAdminTeachersComponent,
    SchoolAdminStudentsComponent,
    SchoolAdminStudentsImportComponent,
    SchoolAdminTeacherDetailComponent,
    TeacherInvitationSignupComponent,
    EvoCircleCheckmarkComponent,
    SchoolAdminStudentDetailComponent,
    BooksComponent,
    ChapterContainerComponent,
    BookContainerComponent,
    SchoolAdminGroupsComponent,
    SchoolAdminGroupDetailComponent,
    SchoolAdminTeachersInvitationsComponent,
    SchoolAdminTeachersInvitationImportComponent,
    SchoolAdminMobileSidebarComponent,
    EvoAdminSchoolUploadUsersInvitationsComponent,
    EvoAdminSchoolUsersInvitationsComponent,
    StudentProfileComponent,
    ActivityChartsComponent,
    SkillProgressChartComponent,
    NewSettingsPageComponent,
    SettingsBarComponent,
    ProfileSettingsComponent,
    PasswordSettingsComponent,
    NewBillingSettingsComponent,
    AuthSettingsComponent,
    ManageSettingsButtonComponent,
    ToggleSwitchComponent,
    AccountNameComponent,
    BookDetailComponent,
    BookInfosComponent,
    NewChapterComponent,
    AttemptContainerComponent,
    SelectStudentComponent,
    CircleProgressBarComponent,
    SchoolAdminLicenseComponent,
    DatepickerComponent,
    UserSettingsModalComponent,
    AccountsSettingsComponent,
    SettingsStudentProfileComponent,
    EvoDatePickerComponent,
    EvoDatePickerInputComponent,
    PlatformAnswerFilterComponent,
    EvoSegmentedControlComponent,
    SchoolAdminGroupDetailStudentsComponent,
    SchoolAdminGroupDetailTeachersComponent,
    SchoolAdminGroupDetailSettingsComponent,
    EvoStudentListViewComponent,
    SchoolAdminGroupDetailAddStudentsComponent,
    EvoTeacherListViewComponent,
    SchoolAdminGroupDetailAddTeachersComponent,
    EvoUsersListViewComponent,
    EvoSchoolsListViewComponent,
    EvoGroupsListViewComponent,
    EvoAdminSchoolStudentsComponent,
    EvoAdminSchoolTeachersComponent,
    EvoAdminSchoolLicensesComponent,
    EvoAdminSchoolSettingsComponent,
    EvoAdminSchoolTeachersEditSettingsComponent,
    EvoAdminSchoolTeachersEditStudentsComponent,
    EvoAdminSchoolTeachersEditGroupsComponent,
    EvoQuestionListViewComponent,
    EvoSingleDivisionComponent,
    EvoMonthYearPickerComponent,
    EvoSkillGroupsListViewComponent,
    AdminSkillGroupQuestionsComponent,
    AdminSkillGroupSettingsComponent,
    TeacherLinkInvitationComponent,
    SelectSchoolComponent,
    SchoolAdminStudentMultiDeleteComponent,
    EvoPassModalComponent,
    EvoCornerNameFromImageComponent,
    EvoFractionSetupFromImageComponent,
    EvoProportionsSetupFromImageComponent,
    EvoTranslate2dComponent,
    AdminPlacementTestComponent,
    AdminPlacementTestEditComponent,
    EvoPlacementTestsListViewComponent,
    AdminPlacementTestSettingsComponent,
    AdminPlacementTestStructureComponent,
    AdminPlacementTestRequisiteSkillsComponent,
    AdminQuestionSelectionListComponent,
    AdminPlacementTestQuestionContainerComponent,
    AdminPlacementTestQuestionModalComponent,
    EvoObjectiveListViewComponent,
    AdminObjectiveSettingsComponent,
    AdminObjectiveSkillsComponent,
    AdminObjectiveSkillGroupsComponent,
    AdminPlacementTestSelectionListComponent,
    EvoAuthenticationFailureModalComponent,
    EvoCreateGroupModalComponent,
    SchoolAdminGroupDetailStudentContainerComponent,
    SchoolAdminGroupAddExistingStudentComponent,
    EvoStudentFilterButtonComponent,
    SchoolAdminGroupAddNewStudentComponent,
    AdminBookSelectionListComponent,
    EvoQuestionFilterButtonComponent,
    AdminObjectiveArchivedComponent,
    AdminPlacementTestArchivedComponent,
    AdminSchoolSelectionComponent,
    AdminUserSettingsComponent,
    AdminUserStudentsComponent,
    GroupRootComponent,
    GroupDashboardComponent,
    GroupSidebarComponent,
    GroupHeaderComponent,
    GroupDashObjectiveComponent,
    GroupDashActivityComponent,
    GroupDashSkillsOverviewComponent,
    AdminSkillGroupsSelectionListComponent,
    AppLinkComponent,
    GroupStudentsComponent,
    GroupDashProblemAreasComponent,
    GroupDashStudentSessionsComponent,
    EvoPerimeterFromShapeComponent,
    EvoAreaFromShapeComponent,
    EvoRoundingWholeNumbersComponent,
    EvoReusableImageTypeComponent,
    EvoReusableMultipleImageTypesComponent,
    EvoBarChartComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    QRCodeModule,
    ChartsModule,
    NgbModule,
    LayoutModule,
    RecaptchaModule,
    // https://www.codeandweb.com/babeledit/tutorials/how-to-translate-your-angular6-app-with-ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [ModalService, {
    provide: RECAPTCHA_V3_SITE_KEY,
    useValue: environment.recaptchaV3Key
  }],
  bootstrap: [AppComponent]
})

export class AppModule {

}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
