import {Component, OnInit, ViewChild} from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {SchoolAdminStudentService} from '../../../../services/school/school-admin-student.service';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../../../shared/modal/modal.service';
import {ErrorModel} from '../../../../models/shared/error.model';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {EVOSegmentedControlItem} from '../../../../shared/evo-segmented-control/evo-segmented-control.component';
import {HelperModel} from '../../../../models/extensions/helper.model';
import {AdminSchoolStudentService} from '../../../../services/admin/admin-school-student.service';

@Component({
  selector: 'app-school-school-student-detail',
  templateUrl: './school-admin-student-detail.component.html',
  styleUrls: ['./school-admin-student-detail.component.css']
})
export class SchoolAdminStudentDetailComponent implements OnInit {

  @ViewChild('qrimage', { static: false }) parent;

  student: StudentModel = new StudentModel();
  password = '';

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('profile', 'reusable.profile'),
    new EVOSegmentedControlItem('qrCode', 'reusable.qrCode')
  ];

  currentItem = this.controls[0];

  constructor(private router: Router,
              private route: ActivatedRoute,
              public tSv: TranslateService,
              private modalSv: ModalService,
              private studentSv: SchoolAdminStudentService) { }

  ngOnInit() {

    this.route.params.subscribe((params) => {
      if (params.studentId) {
        this.studentSv.fetchStudentById(params.studentId).subscribe((student) => {
          this.student = student;
        });
      }
    });

  }

  onCopyIdClicked() {
    StringExtensionModel.copyStringToClipboard(this.student._id);
    this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.copySuccess')).subscribe(() => {

    });
  }

  onUpdateStudentClicked() {
    this.studentSv.updateStudent(this.student).subscribe(() => {
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
        this.tSv.instant('schoolAdministration.students.updateStudentMessage')).subscribe();
    });
  }

  onDeleteStudentClicked() {

    this.modalSv.showChoiceModal(this.tSv.instant('reusable.success'),
      this.tSv.instant('schoolAdministration.students.deleteStudentModalWarning')).subscribe((response) => {
        if (response === true) {
          this.studentSv.deleteStudent(this.student._id, this.password).subscribe(() => {
            this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
              this.tSv.instant('schoolAdministration.students.deleteStudentModalSuccess')).subscribe(() => {
                this.router.navigate(['schooladmin', 'students']);
            });
          }, (err: ErrorModel) => {
            this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), err.message);
          });
        }
    });

  }

  isPasswordButtonDisabled() {

    if (this.password.length >= 6) {
      return false;
    }

    return true;
  }

  /** QR Code Logic */

  onQRCodeEnable() {

    const qrCodeStatus = this.student.isQRCodeAuthEnabled;
    this.student.isQRCodeAuthEnabled = !qrCodeStatus;

    this.studentSv.updateStudent(this.student).subscribe((student) => {
      this.student = student;
    }, (err: ErrorModel) => {
      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), this.tSv.instant(''));
    });

  }

  onClose() {

    this.router.navigate(['schooladmin', 'students']);

  }

  hasQRCode() {

    if (this.student.qrCodePassword) {

      return true;
    }

    return false;

  }

  isQRCodeEnabled() {

    return this.student.isQRCodeAuthEnabled;
  }

  newCode() {

    if (this.student) {
      this.studentSv.newQRCode(this.student._id).subscribe((student) => {
        this.student = student;
      });
    }

  }

  saveAsImage(parent: any) {

    const parentElement = parent.el.nativeElement.querySelector('img').src;
    const blobData = HelperModel.convertBase64ToBlob(parentElement);

    const fileName = 'QRCode ' + this.student.fullName(this.tSv).replace('.', '') + '.png';

    HelperModel.saveAsImage(fileName, blobData);

  }

}
