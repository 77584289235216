import {Component, Input, OnInit} from '@angular/core';
import {GroupModel} from '../../../../models/school/group.model';
import {SkillGroupStructureModel} from '../../../../models/student/skillGroupStatusModel';
import {UserModel} from '../../../../models/authentication/user.model';

@Component({
  selector: 'app-group-dash-objective',
  templateUrl: './group-dash-objective.component.html',
  styleUrls: ['./group-dash-objective.component.css']
})
export class GroupDashObjectiveComponent implements OnInit {

  @Input() group: GroupModel;
  @Input() skillGroupStructure: SkillGroupStructureModel;

  constructor() { }

  ngOnInit() {
  }

  groupName(): string {

    if (this.group && this.group.objective) {

      return this.group.objective.translatedName[UserModel.getCurrent().culture];
    }

    return '';
  }

  getProblemAreas(): number {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.numProblemPerc * 100);
    }

    return 0;
  }

  getProblemAreasString(): string {

    return this.getProblemAreas().toFixed(2);

  }

  getNotStarted(): number {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.notStartedPerc * 100);
    }

    return 0;

  }

  getNotStartedString(): string {

    return this.getNotStarted().toFixed(2);

  }

  getLearning(): number {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.learningPerc * 100);
    }

    return 0;

  }

  getLearningString(): string {

    return this.getLearning().toFixed(2);

  }

  getPracticing(): number {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.practicingPerc * 100);
    }

    return 0;

  }

  getPracticingString(): string {

    return this.getPracticing().toFixed(2);

  }

  getMastering(): number {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.masteringPerc * 100);
    }

    return 0;

  }

  getMasteringString(): string {

    return this.getMastering().toFixed(2);

  }

  getMastered(): number {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.masteredPerc * 100);
    }

    return 0;

  }

  getMasteredString(): string {

    return this.getMastered().toFixed(2);

  }

  getTotalMastery(): string {

    if (this.skillGroupStructure) {
      return (this.skillGroupStructure.mastery.totalProgress * 100).toFixed(2);
    }

    return '';
  }

}
