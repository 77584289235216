import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {GroupQueryModel} from '../../../../models/school/group.model';
import {EVOStudentListSort} from '../../../../shared/evo-student-list-view/evo-student-list-view.component';
import {ModalService} from '../../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {SchoolAdminGroupService} from '../../../../services/school/school-admin-group.service';
import {concat, forkJoin, Observable} from 'rxjs';
import {ErrorModel} from '../../../../models/shared/error.model';
import {SchoolAdminStudentService} from '../../../../services/school/school-admin-student.service';
import {StudentFilterModel} from '../../../../models/filters/student-filter.model';
import {EvoPassModalService} from '../../../../shared/evo-pass-modal/evo-pass-modal.service';

@Component({
  selector: 'app-school-admin-student-multi-delete',
  templateUrl: './school-admin-student-multi-delete.component.html',
  styleUrls: ['./school-admin-student-multi-delete.component.css']
})
export class SchoolAdminStudentMultiDeleteComponent implements OnInit {

  _show = false;
  @Input('show')
  set show(show: boolean) {
    this._show = show;
    this.fetchStudents();
  }

  get show(): boolean {
    return this._show;
  }

  hasMoreData = false;
  students: StudentModel[] = [];
  selectedStudents: StudentModel[] = [];
  /**
   * Used when netowkring to disable the button
   */
  deletingStudents = false;

  @Output() close = new EventEmitter();
  @Output() studentsDeleted = new EventEmitter();

  showFilter = false;
  filter = new StudentFilterModel();

  sort = new EVOStudentListSort();

  showStudentDelete = false;
  numStudentsToDelete = 0;
  numStudentsDeleted = 0;

  constructor(private modalSv: ModalService,
              private tSv: TranslateService,
              private passModalSv: EvoPassModalService,
              private studentSv: SchoolAdminStudentService) { }

  ngOnInit() {
    this.filter.sort = 1;
  }

  fetchStudents() {

    if (this._show) {

      this.filter.skip = this.students.length;

      this.studentSv.fetchStudents(this.filter).subscribe((students) => {

        this.hasMoreData = students.length === this.filter.limit;

        for (const student of students) {
          if (this.students.filter((s) => s._id === student._id).length === 0) {
            this.students.push(student);
          }
        }

      });

    }

  }

  onSearchStudents(studentName: string) {

    if (studentName === '' || !studentName) {
      this.filter.name = null;
    } else {
      this.filter.name = studentName;
    }

    this.students = [];
    this.selectedStudents = [];

    this.fetchStudents();

  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.updateWithListSort(sort);
    this.students = [];
    this.selectedStudents = [];

    this.fetchStudents();

  }

  getNumFilters(): string {

    let numFilters = 0;

    if (this.filter.name) {
      numFilters++;
    }

    if (this.filter.fromAge || this.filter.toAge) {
      numFilters++;
    }

    return numFilters.toFixed(0);

  }

  onApplyClicked() {

    this.filter.skip = 0;
    this.students = [];
    this.fetchStudents();
    this.showFilter = false;

  }

  onClearClicked() {

    this.filter.toAge = undefined;
    this.filter.fromAge = undefined;

    this.filter.skip = 0;
    this.students = [];
    this.fetchStudents();
    this.showFilter = false;

  }

  onDeleteClicked() {

    this.passModalSv.confirmPassModal().subscribe((pass) => {

      if (pass) {

        const studentsToDelete = [];
        for (const student of this.selectedStudents) {
          studentsToDelete.push(this.studentSv.deleteStudent(student._id, pass));
        }

        this.numStudentsToDelete = studentsToDelete.length;
        this.numStudentsDeleted = 0;
        this.showStudentDelete = true;

        this.recursivelyDeleteStudents(studentsToDelete, 0);
      }

    });

  }

  recursivelyDeleteStudents(deletedStudents: Observable<boolean>[], index: number) {

    if (this.numStudentsDeleted === this.numStudentsToDelete) {

      this.showStudentDelete = false;

      this.selectedStudents = [];
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));

      if (this.studentsDeleted) {
        this.studentsDeleted.emit();
      }

      this.numStudentsDeleted = 0;
      this.numStudentsToDelete = 0;

      this.students = [];
      this.fetchStudents();

    } else {

      deletedStudents[index].subscribe(() => {

        this.numStudentsDeleted++;
        this.recursivelyDeleteStudents(deletedStudents, this.numStudentsDeleted);

      }, (err: ErrorModel) => {
        this.showStudentDelete = false;
        this.modalSv.showTranslatedErrorModal(err);
      });

    }

  }

  onCloseClicked() {
    this._show = false;
    if (this.close) {
      this.close.emit();
    }
  }

}
