import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {EVOQuestionFilter} from '../../services/admin/admin-question.service';
import {QuestionModel} from '../../models/questions/question.model';

@Component({
  selector: 'app-evo-question-filter-button',
  templateUrl: './evo-question-filter-button.component.html',
  styleUrls: ['./evo-question-filter-button.component.css']
})
export class EvoQuestionFilterButtonComponent implements OnInit {

  @Input() questionFilter: EVOQuestionFilter;
  @Output() filterChanged = new EventEmitter<EVOQuestionFilter>();

  QuestionModel = QuestionModel;

  isOpen = false;

  constructor() { }

  ngOnInit() {
  }

  onButtonClicked() {
    this.isOpen = !this.isOpen;
  }

  getValue(): number {

    if (this.questionFilter) {

    }

    return null;

  }

  setValue(value: string, key) {
    if (this.questionFilter) {
      this.questionFilter[key] = value;
    }
  }

  getCurrentFilter(key: string) {

    if (this.questionFilter[key] === null || this.questionFilter[key] === undefined) {
      return 'none';
    }

    if (typeof this.questionFilter[key] === 'boolean') {
      if (this.questionFilter[key] === true) {
        return 'true';
      } else {
        return 'false';
      }
    }

    return this.questionFilter[key];
  }

  onClear() {

    this.questionFilter.category = null;
    this.questionFilter.subCategory = null;

    this.onEmit();

  }

  onApply() {

    this.onEmit();

  }

  onEmit() {

    this.isOpen = false;

    if (this.filterChanged) {
      this.filterChanged.emit(this.questionFilter);
    }

  }

  numFilters(): number {

    /*
    if (this.questionFilter.toAge != null) {
      return 1;
    }
    */

    return 0;
  }

}
