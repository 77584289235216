import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../../models/authentication/user.model';
import {AdminUserService} from '../../../services/admin/admin-user.service';
import {EvoLangDropdownComponent} from '../../../shared/evo-lang-dropdown/evo-lang-dropdown.component';
import {SchoolModel} from '../../../models/school/school.model';
import {StudentModel} from '../../../models/authentication/student.model';
import {AdminSchoolService} from '../../../services/admin/admin-school.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AdminPasswordValidationService} from '../admin-passord-validation/admin-password-validation.service';
import {EVOSegmentedControlItem} from '../../../shared/evo-segmented-control/evo-segmented-control.component';

@Component({
  selector: 'app-school-user-edit',
  templateUrl: './admin-user-edit.component.html',
  styleUrls: ['./admin-user-edit.component.css']
})
export class AdminUserEditComponent implements OnInit {

  user: UserModel = new UserModel();

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('students', 'schoolAdministration.students.title'),
    new EVOSegmentedControlItem('teachers', 'schoolAdministration.teachers.title'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem = this.controls[0];

  constructor(
    private adminUserSv: AdminUserService,
    private adminSchoolSv: AdminSchoolService,
    public adminPasswordValidationSrv: AdminPasswordValidationService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    for (const item of this.controls) {
      if (item.key === page) {
        this.currentItem = item;
      }
    }

    this.route.params.subscribe((param: Params) => {

      const userId = param.userId;
      this.adminUserSv.fetchUserById(userId).subscribe((user) => {
        this.user = user;
      });

    });

    // const userId: string = String(this.route.snapshot.paramMap.get('userId'));

  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.currentItem = item;
    this.router.navigate(['admin', 'users', this.user._id, 'detail', item.key]);
  }

}
