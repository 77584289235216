import {Component, Input, OnInit} from '@angular/core';
import {CornerNameFromImageModel} from '../../../../../models/questions/subModels/cornerNameFromImage.model';
import {QuestionModel} from '../../../../../models/questions/question.model';
import {EvoListSelectorService} from '../../../../../shared/evo-list-selector/evo-list-selector.service';

@Component({
  selector: 'app-evo-corner-name-from-image',
  templateUrl: './evo-corner-name-from-image.component.html',
  styleUrls: ['./evo-corner-name-from-image.component.css']
})
export class EvoCornerNameFromImageComponent implements OnInit {

  QuestionModel = QuestionModel;
  @Input() question: QuestionModel = new CornerNameFromImageModel();

  constructor(private selectorSv: EvoListSelectorService) { }

  ngOnInit() {
  }

  castedQuestion(): CornerNameFromImageModel {

    return <CornerNameFromImageModel>this.question;
  }

  removeCornerType(index: number) {
    if (index < this.castedQuestion().model.possibleAnswers.length) {
      this.castedQuestion().model.possibleAnswers.splice(index, 1);
    }
  }

  showCornerSelection() {
    this.selectorSv.showList(QuestionModel.CornerTypes.list(), this.castedQuestion().model.possibleAnswers).subscribe((item) => {
      if (item) {
        this.castedQuestion().model.possibleAnswers.push(item);
      }
    });
  }

  showQuestion(): boolean {

    if (this.question) {
      if (this.question.subCategory === QuestionModel.SubCategoryTypes.cornerNameFromImage) {
        return true;
      }
    }

    return false;
  }

}
