import {Component, OnDestroy, OnInit} from '@angular/core';
import {GroupModel, GroupQueryModel} from '../../../../models/school/group.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SchoolAdminGroupService} from '../../../../services/school/school-admin-group.service';
import {ErrorModel} from '../../../../models/shared/error.model';
import {ModalService} from '../../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {EVOSegmentedControlItem} from '../../../../shared/evo-segmented-control/evo-segmented-control.component';
import {Subscription} from 'rxjs';
import {DropdownModel} from '../../../../models/component/dropdown.model';

@Component({
  selector: 'app-school-school-group-detail',
  templateUrl: './school-admin-group-detail.component.html',
  styleUrls: ['./school-admin-group-detail.component.css']
})
export class SchoolAdminGroupDetailComponent implements OnInit, OnDestroy {

  group: GroupModel = new GroupModel();
  groupId: string = null;
  navigationSubscription: Subscription = null;

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('students', 'schoolAdministration.students.title'),
    new EVOSegmentedControlItem('teachers', 'schoolAdministration.teachers.title'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem: EVOSegmentedControlItem = new EVOSegmentedControlItem('students', 'schoolAdministration.students.title');

  dropdownList = [
    new DropdownModel('students', 'schoolAdministration.students.title'),
    new DropdownModel('teachers', 'schoolAdministration.teachers.title'),
    new DropdownModel('settings', 'sidebar.settings')
  ];

  currentDropdownItem: DropdownModel;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private groupSv: SchoolAdminGroupService) {
  }

  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    this.onValueChanged(page);

    this.navigationSubscription = this.router.events.subscribe((event) => {

      if (event instanceof NavigationEnd && this.groupId) {
        this.fetchGroup();
      }

    });

    this.route.params.subscribe((params) => {

      if (params.groupId) {
        this.groupId = params.groupId;
        this.fetchGroup();
      } else {
        this.groupId = params.groupId;
      }

    });
  }

  ngOnDestroy() {

    this.navigationSubscription.unsubscribe();

  }

  fetchGroup() {
    this.groupSv.fetchGroup(this.groupId).subscribe((g) => {
      this.group = g;
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });
  }

  getGroupInitials(): string {

    if (!this.group) {
      return '';
    }

    return this.group.getTwoInitials();
  }

  getGroupName(): string {

    if (!this.group) {
      return '';
    }

    return this.group.name;
  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.onValueChanged(item.key);
    this.onNavigate(item.key);
  }

  onDropdownChanged(key: string) {
    this.onValueChanged(key);
    this.onNavigate(key);
  }

  onNavigate(key: string) {
    this.router.navigate(['schooladmin', 'groups', this.group._id, key]);
  }

  onValueChanged(key: string) {

    for (const item of this.controls) {
      if (item.key === key) {
        this.currentItem = item;
      }
    }

    for (const item of this.dropdownList) {
      if (item.key === key) {
        this.currentDropdownItem = item;
      }
    }

    if (key === 'addNewStudents' || key === 'addExistingStudents') {
      this.currentItem = this.controls[0];
      this.currentDropdownItem = this.dropdownList[0];
    }

  }

}
