import { Injectable } from '@angular/core';
import {Observable, Subscriber} from 'rxjs';
import {GroupModel} from '../../models/school/group.model';
import {TranslateService} from '@ngx-translate/core';
import {ObjectiveModel} from '../../models/edu-admin/objective.model';
import {AdminObjectiveService} from '../../services/admin/admin-objective.service';
import {SchoolAdminGroupService} from '../../services/school/school-admin-group.service';
import {ErrorModel} from '../../models/shared/error.model';
import {ModalService} from '../modal/modal.service';
import {SchoolModel} from '../../models/school/school.model';

@Injectable({
  providedIn: 'root'
})
export class EvoCreateGroupModalService {

  isVisible = false;
  step = 0;
  numSteps = 2;

  // Used to differentiate between school and
  // admin endpoint usage in the future
  type = 'school';
  group = new GroupModel();
  subscriber: Subscriber<GroupModel>;

  // By default, start with none
  currentObjective = 'none';
  objectives: ObjectiveModel[] = [];

  constructor(private tSv: TranslateService,
              private modalSv: ModalService,
              private schoolGroupSv: SchoolAdminGroupService,
              private objectiveSv: AdminObjectiveService) { }

  show(): Observable<GroupModel> {

    this.isVisible = true;

    this.objectiveSv.fetchPublicObjectives(SchoolModel.getCurrent().country).subscribe((objectives) => {
      this.objectives = objectives;
    });

    return new Observable((subscriber: Subscriber<GroupModel>) => {
      this.subscriber = subscriber;
    });

  }

  hide() {

    this.isVisible = false;
    this.step = 0;
    this.group = new GroupModel();

  }

  getMessage() {

    if (this.step === 0) {
      return this.tSv.instant('createGroup.messageStepOne');
    } else if (this.step === 1) {
      return this.tSv.instant('createGroup.messageStepTwo');
    }

    return '';

  }

  getObjectivesDropdown(): { key: string, value: any, iconURL: string }[] {

    const list = [];
    list.push({
      key: 'none',
      value: this.tSv.instant('reusable.none'),
      iconURL: null
    });

    // { key: string, value: any, iconURL: string }[]

    for (const objective of this.objectives) {

      const body = {
        key: objective._id,
        value: objective.getTranslatedNameForUser(),
        iconURL: null
      };

      list.push(body);

    }

    return list;

  }

  nextClicked() {

    if (this.step === 1) {
      // Create the group
      if (this.type === 'school') {

        this.schoolGroupSv.createGroup(this.group).subscribe((group) => {

          this.group = group;
          this.subscriber.next(this.group);
          this.subscriber.complete();
          this.resetState();

        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });

      }

    } else {
      this.step += 1;
    }

  }

  previousClicked() {

    if (this.step > 0) {
      this.step -= 1;
    }

  }

  onObjectiveSelected(objectiveId: string) {

    // Can be none
    this.currentObjective = objectiveId;

    for (const objective of this.objectives) {
      if (objective._id === objectiveId) {
        this.group.objectiveId = objective._id;
        this.group.grade = objective.grade;
      }
    }

  }

  nextButtonDisabled(): boolean {

    if (this.step === 0) {

      if (this.group.name && this.group.name.length > 0) {
        return false;
      }

    } else if (this.step === 1) {

      // No validation because none can be chosen.
      return false;

    }

    return true;
  }

  getButtonTitle() {

    if (this.step === 0) {

      return this.tSv.instant('reusable.next');
    }

    return this.tSv.instant('reusable.create');
  }

  resetState() {
    this.isVisible = false;
    this.step = 0;
    this.group = new GroupModel();
  }

}
