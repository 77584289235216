import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SchoolModel } from '../../../models/school/school.model';
import { AdminSchoolService } from '../../../services/admin/admin-school.service';
import {EVOSegmentedControlItem} from '../../../shared/evo-segmented-control/evo-segmented-control.component';

@Component({
  selector: 'app-evo-school-school-edit',
  templateUrl: './evo-admin-school-edit.component.html',
  styleUrls: ['./evo-admin-school-edit.component.css']
})
export class EvoAdminSchoolEditComponent implements OnInit {

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('students', 'schoolAdministration.students.title'),
    new EVOSegmentedControlItem('teachers', 'schoolAdministration.teachers.title'),
    new EVOSegmentedControlItem('licenses', 'schoolAdministration.licenses.title'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem = this.controls[0];
  school: SchoolModel = new SchoolModel();

  constructor(private schoolSv: AdminSchoolService,
    private route: ActivatedRoute,
    private router: Router) {
  }
  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    for (const item of this.controls) {
      if (item.key === page) {
        this.currentItem = item;
      }
    }

    this.route.params.subscribe((params: Params) => {

      this.schoolSv.fetchSchoolById(params.schoolId).subscribe((school) => {
        this.school = school;
      });

    });

  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.currentItem = item;
    this.router.navigate(['admin', 'schools', this.school._id, 'detail', item.key]);
  }

}
