import { Component, OnInit } from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AdminSchoolStudentService} from '../../../../services/admin/admin-school-student.service';
import {StudentFilterModel} from '../../../../models/filters/student-filter.model';
import {EVOStudentListSort} from '../../../../shared/evo-student-list-view/evo-student-list-view.component';

@Component({
  selector: 'app-evo-admin-school-students',
  templateUrl: './evo-admin-school-students.component.html',
  styleUrls: ['./evo-admin-school-students.component.css']
})
export class EvoAdminSchoolStudentsComponent implements OnInit {

  filter = new StudentFilterModel();
  students: StudentModel[] = [];
  schoolId: string;
  hasMoreData = true;

  constructor(private adminSchoolStudentSv: AdminSchoolStudentService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;

    });

    this.filter.limit = 20;
    this.fetchStudents();

  }

  fetchStudents() {

    this.filter.skip = this.students.length;
    this.adminSchoolStudentSv.fetchSchoolStudents(this.schoolId, this.filter).subscribe((students) => {

      this.hasMoreData = students.length / this.filter.limit === 1;

      for (const student of students) {
        if (this.students.filter((s) => s._id === student._id).length === 0) {
          this.students.push(student);
        }
      }

    });

  }

  onSearchStudents(name: string) {

    if (name) {
      this.filter.name = name;
    } else {
      this.filter.name = undefined;
    }

    this.students = [];
    this.fetchStudents();

  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.updateWithListSort(sort);
    this.students = [];
    this.fetchStudents();

  }

  onImportStudentClicked() {

    this.router.navigate(['admin', 'schools', this.schoolId, 'students', 'import'])

  }

  onAddStudentClicked() {

    this.router.navigate(['admin', 'schools', this.schoolId, 'students', 'create']);

  }

  onStudentClicked(student: StudentModel) {

    this.router.navigate(['admin', 'schools', this.schoolId, 'students', student._id, 'edit']);

  }

}
