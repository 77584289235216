import { Component, OnInit } from '@angular/core';
import {AdminObjectiveService} from '../../../../services/admin/admin-objective.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ObjectiveFilter, ObjectiveModel} from '../../../../models/edu-admin/objective.model';
import {
  AdminPlacementTestSelectionListService
} from '../../../shared/admin-placement-test-selection-list/admin-placement-test-selection-list.service';
import {CountryModel} from '../../../../models/localization/country.model';
import {AdminBookSelectionListService} from '../../../shared/admin-book-selection-list/admin-book-selection-list.service';
import {ModalService} from '../../../../shared/modal/modal.service';
import {ErrorModel} from '../../../../models/shared/error.model';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-admin-objective-settings',
  templateUrl: './admin-objective-settings.component.html',
  styleUrls: ['./admin-objective-settings.component.css']
})
export class AdminObjectiveSettingsComponent implements OnInit {

  objective: ObjectiveModel;
  objectiveId: string;

  filter = new ObjectiveFilter();

  showObjectiveCopy = false;
  copiedObjective: ObjectiveModel;

  password = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private selectPlacementSv: AdminPlacementTestSelectionListService,
              private selectBookSv: AdminBookSelectionListService,
              private modalSv: ModalService,
              private objSv: AdminObjectiveService) { }

  ngOnInit() {

    this.filter.populatePlacementTest = true;
    this.filter.populateBooks = true;

    this.route.parent.params.subscribe((params: Params) => {

      this.objectiveId = params.objectiveId;
      this.fetchObjective();

    });

  }

  fetchObjective() {

    this.objSv.fetchObjectiveById(this.objectiveId, this.filter).subscribe((obj) => {

      this.objective = obj;

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  updateObjective() {

    this.objSv.updateObjective(this.objective, this.filter).subscribe((obj) => {

      this.objective = obj;
      this.modalSv.showAlertModal('Success', 'Objectives successfully updated');

    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  selectPlacementTest() {

    this.selectPlacementSv.show(this.objective.country).subscribe((placements) => {

      if (placements && placements.length > 0) {
        this.objective.placementTestId = placements[0]._id;
        this.objective.placementTest = placements[0];
        this.updateObjective();
      }

    });

  }

  selectBookVersion() {

    this.selectBookSv.show().subscribe((bookVersion) => {

      if (bookVersion) {
        this.objective.bookVersionId = bookVersion._id;
        this.objective.bookId = bookVersion.bookId;
        this.updateObjective();
      }

    });

  }

  onCopyObjectiveClicked() {

    this.showObjectiveCopy = true;
    const json = JSON.parse(JSON.stringify(this.objective));
    this.copiedObjective = ObjectiveModel.generateModel(json);
    console.log('copied objective: ' + JSON.stringify(this.copiedObjective, null, 4));
  }

  onCreateNewObjective() {

    this.copiedObjective.placementTestId = null;

    this.objSv.createObjective(this.copiedObjective).subscribe((newObj) => {
        this.router.navigate(['admin', 'objectives', newObj._id, 'detail', 'skills']);
    });

  }

  grade(): string {

    if (this.objective.placementTest.grade === undefined || this.objective.placementTest.grade === null) {
      return 'N/A';
    }

    return this.objective.placementTest.grade.toString(10);

  }

  country(): string {

    return CountryModel.title(this.objective.placementTest.country);

  }

  onArchiveQuestion(archive: boolean) {

    return this.objSv.archiveObjective(this.objective, archive, this.password).subscribe((obj) => {

      this.objective = obj;
      this.password = '';

    })

  }

}
