import { Component, OnInit } from '@angular/core';
import {PlacementTestModel, PlacementTestSectionModel, PlacementTestSectionQuestionModel} from '../../../../models/edu-admin/placement-test.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ModalService} from '../../../../shared/modal/modal.service';
import {AdminPlacementTestService} from '../../../../services/admin/admin-placement-test.service';
import {ErrorModel} from '../../../../models/shared/error.model';
import {QuestionModel} from '../../../../models/questions/question.model';
import {EVOQuestionFilter, AdminQuestionService} from '../../../../services/admin/admin-question.service';
import {AdminQuestionSelectionListService} from '../../../shared/admin-question-selection-list/admin-question-selection-list.service';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {
  AdminPlacementTestQuestionModalService
} from './shared/admin-placement-test-question-modal/admin-placement-test-question-modal.service';

@Component({
  selector: 'app-admin-placement-test-structure',
  templateUrl: './admin-placement-test-structure.component.html',
  styleUrls: ['./admin-placement-test-structure.component.css']
})
export class AdminPlacementTestStructureComponent implements OnInit {

  updatingPlacement = true;
  placementTestId: string;
  placementTest: PlacementTestModel;

  currentSection: PlacementTestSectionModel;
  isNewSection = false;

  currentSectionQuestion: PlacementTestSectionQuestionModel;
  isNewSectionQuestion = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private selectQSv: AdminQuestionSelectionListService,
              private placementSv: AdminPlacementTestService,
              private adminQSv: AdminQuestionService,
              private placementQuestionModalSv: AdminPlacementTestQuestionModalService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.placementTestId = params.testId;
      this.fetchPlacementTest();

    });

  }

  fetchPlacementTest() {

    this.placementSv.fetchPlacementTestById(this.placementTestId, true).subscribe((placementTest) => {

      this.placementTest = placementTest;

    });

  }

  onSaveClicked() {

    this.updatingPlacement = true;
    this.placementSv.updatePlacementTestSections(this.placementTest, true).subscribe((placement) => {
      this.updatingPlacement = false;
      this.currentSection = null;
      this.modalSv.showAlertModal('Success', 'Placement test information updated successfully');
    }, (err: ErrorModel) => {
      this.updatingPlacement = false;
      this.currentSection = null;
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  showNewSection() {

    this.isNewSection = true;
    this.currentSection = new PlacementTestSectionModel();

  }

  onUpdateSection() {

    if (this.isNewSection) {
      this.placementTest.sections.push(this.currentSection);
    }

    this.onSaveClicked();

  }

  onMoveUp(section: PlacementTestSectionModel, index: number) {

    this.placementTest.sections.splice(index, 1);
    this.placementTest.sections.splice(index - 1, 0, section);

    this.onSaveClicked();

  }

  onMoveDown(section: PlacementTestSectionModel, index: number) {

    this.placementTest.sections.splice(index, 1);
    this.placementTest.sections.splice(index + 1, 0, section);

    this.onSaveClicked();

  }

  onEditSection(section: PlacementTestSectionModel) {

    this.isNewSection = false;
    this.currentSection = section;
    this.currentSectionQuestion = null;

  }

  onRemoveSection(section: PlacementTestSectionModel) {

    this.modalSv.showChoiceModal('Warning',
      'Are you sure you want to remove the section? This cannot be undone',
      'Yes',
      'No').subscribe((response) => {

      if (response === true) {

        const index = this.placementTest.sections.indexOf(section);

        if (index !== -1) {
          this.placementTest.sections.splice(index, 1);
        }

        this.onSaveClicked();

      }

    });

  }

  onCreateNewSectionQuestion(sectionQuestion: PlacementTestSectionModel) {

    const excludedQuestionIds = StringExtensionModel.queryArrayString(sectionQuestion.orderedQuestions, 'questionId');

    this.isNewSectionQuestion = true;
    this.currentSection = sectionQuestion;
    this.currentSectionQuestion = new PlacementTestSectionQuestionModel();

    this.selectQSv.show(excludedQuestionIds).subscribe((questions) => {

      if (questions && questions.length > 0) {
        this.currentSectionQuestion.question = questions[0];
        this.currentSectionQuestion.questionId = questions[0]._id;
        this.onUpdateQuestion();
      }

    });

  }

  onEditSectionQuestion(section: PlacementTestSectionModel, sectionQuestion: PlacementTestSectionQuestionModel) {

    this.currentSection = section;
    this.currentSectionQuestion = sectionQuestion;

    this.placementQuestionModalSv.show(this.placementTest, this.currentSection, this.currentSectionQuestion).subscribe((updatedPlacementTest) => {

      this.placementTest = updatedPlacementTest;

    });

  }

  onMoveSectionQuestionRight(section: PlacementTestSectionModel, sectionQ: PlacementTestSectionQuestionModel, index: number) {

    section.orderedQuestions.splice(index, 1);
    section.orderedQuestions.splice(index + 1, 0, sectionQ);

    this.onSaveClicked();

  }

  onMoveSectionQuestionLeft(section: PlacementTestSectionModel, sectionQ: PlacementTestSectionQuestionModel, index: number) {

    section.orderedQuestions.splice(index, 1);
    section.orderedQuestions.splice(index - 1, 0, sectionQ);

    this.onSaveClicked();

  }

  onUpdateQuestion() {

    if (this.isNewSectionQuestion) {

      this.currentSection.orderedQuestions.push(this.currentSectionQuestion);

    }

    this.currentSection = null;
    this.currentSectionQuestion = null;

    this.placementSv.updatePlacementTestSections(this.placementTest, true).subscribe((placement) => {
      this.placementTest = placement;
      this.modalSv.showAlertModal('Success', 'New question added successfully');
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

}
