import {SkillGroupModel} from '../edu-admin/skillGroup.model';
import {SkillStatusModel} from './skill.model';
import {StudentModel} from '../authentication/student.model';

export class SkillGroupStructureModel {

  data: Record<string, Record<string, SkillGroupStatusModel>> = {};
  students: StudentModel[] = [];
  skillGroups: SkillGroupModel[] = [];
  mastery = {
    numProblemAreas: 0,
    numProblemPerc: 0,
    numNotStarted: 0,
    notStartedPerc: 0,
    numLearning: 0,
    learningPerc: 0,
    numPracticing: 0,
    practicingPerc: 0,
    numMastering: 0,
    masteringPerc: 0,
    numMastered: 0,
    masteredPerc: 0,
    totalMastery: 0,
    totalProgress: 0
  };

  constructor(students: StudentModel[], skillGroups: SkillGroupModel[], skillStatuses: SkillStatusModel[]) {
    this.setup(students, skillGroups, skillStatuses);
  }

  skillGroupStatusForStudent(studentId: string, skillGroupId: string): SkillGroupStatusModel {

    const studentRecord = this.data[studentId];
    if (studentRecord) {

      const skillGroupStatus = studentRecord[skillGroupId];
      return skillGroupStatus;

    }

    return null;

  }

  masteryForStudentSkillGroupStatus(studentId: string, skillGroupId: string): number {

    const sGroup = this.skillGroupStatusForStudent(studentId, skillGroupId);

    if (sGroup) {
      return sGroup.mastery();
    }

    return 0;
  }

  setup(students: StudentModel[], skillGroups: SkillGroupModel[], skillStatuses: SkillStatusModel[]) {

    this.students = students;
    this.skillGroups = skillGroups;

    // Setup individual student and skill group record
    for (const student of students) {

      const studentRecord: Record<string, SkillGroupStatusModel> = {};

      for (const skillGroup of skillGroups) {
        studentRecord[skillGroup._id] = new SkillGroupStatusModel(student._id, skillGroup);
      }

      this.data[student._id] = studentRecord;

    }

    for (const skillStatus of skillStatuses) {

      const currentStatus = this.skillGroupStatusForStudent(skillStatus.studentId, skillStatus.skillGroupId);

      // Current status should always exist
      if (currentStatus) {
        currentStatus.addSkillStatus(skillStatus);
      }

    }

    this.masteredSkillsSetup();

  }

  private masteredSkillsSetup() {

    let numMastered = 0;
    let numMastering = 0;
    let numPracticing = 0;
    let numLearning = 0;
    let numNotStarted = 0;
    let numProblemAreas = 0;
    let totalMastery = 0;

    for (const studentKey of Object.keys(this.data)) {

      const skillGroupsForStudent = this.data[studentKey];
      for (const skillGroup of this.skillGroups) {

        const mastery = skillGroupsForStudent[skillGroup._id].mastery();
        if (mastery >= 0) {
          totalMastery += mastery;
        }

        if (mastery === -2) {
          numProblemAreas += 1;
        } else if (mastery === -1) {
          numNotStarted += 1;
        } else if (mastery >= 0 && mastery < 0.5) {
          numLearning += 1;
        } else if (mastery >= 0.5 && mastery < 0.8) {
          numPracticing += 1;
        } else if (mastery >= 0.8 && mastery < 1.0) {
          numMastering += 1;
        } else if (mastery === 1) {
          numMastered += 1;
        }

      }

    }

    const totalSkills = this.students.length * this.skillGroups.length;

    this.mastery.totalMastery = totalMastery;
    this.mastery.totalProgress = totalMastery / totalSkills;

    this.mastery.numProblemAreas = numProblemAreas;
    this.mastery.numProblemPerc = numProblemAreas / totalSkills;

    this.mastery.numNotStarted = numNotStarted;
    this.mastery.notStartedPerc = numNotStarted / totalSkills;

    this.mastery.numLearning = numLearning;
    this.mastery.learningPerc = numLearning / totalSkills;

    this.mastery.numPracticing = numPracticing;
    this.mastery.practicingPerc = numPracticing / totalSkills;

    this.mastery.numMastering = numMastering;
    this.mastery.masteringPerc = numMastering / totalSkills;

    this.mastery.numMastered = numMastered;
    this.mastery.masteredPerc = numMastered / totalSkills;

  }

}

export class SkillGroupStatusModel {

  studentId: string;
  questionId: string;
  skillGroupId: string;
  skillGroup: SkillGroupModel;
  skillStatuses: SkillStatusModel[] = [];

  constructor(studentId: string, skillGroup: SkillGroupModel) {
    this.studentId = studentId;
    this.skillGroupId = skillGroup._id;
    this.skillGroup = skillGroup;
  }

  /**
   * Adds all the mastery together for the skills of the skill group.
   * This method will return -1 if there are no skills in the group
   * This method will return -2 if there is a skill that has >= 3
   * num consecutively wrong answers which constitutes a problem area
   */
  mastery(): number {

    let mastery = 0;
    const numSkillsInGroup = this.skillGroup.questionIds.length;
    if (numSkillsInGroup === 0) {
      return 1;
    }

    if (this.skillStatuses.length > 0) {
      for (const skill of this.skillStatuses) {
        mastery += skill.mastery;
        if (skill.numWrongConsecutively >= 3) {
          return -2;
        }
      }
    } else {
      // If we do not have skill statuses it means we haven't started this
      return -1;
    }

    mastery = mastery / numSkillsInGroup;

    return mastery;

  }

  addSkillStatus(skillStatus: SkillStatusModel) {

    if (skillStatus.studentId === this.studentId && this.skillGroup) {

      for (const qId of this.skillGroup.questionIds) {
        if (qId === skillStatus.questionId) {
          this.skillStatuses.push(skillStatus);
          break;
        }
      }

    }

  }

}
