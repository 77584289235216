import { Component, OnInit } from '@angular/core';
import {CountryModel} from '../../../models/localization/country.model';
import {PlacementTestFilter, PlacementTestModel} from '../../../models/edu-admin/placement-test.model';
import {EVOPlacementTestListSort} from '../../../shared/evo-placement-tests-list-view/evo-placement-tests-list-view.component';
import {AdminPlacementTestService} from '../../../services/admin/admin-placement-test.service';
import {ModalService} from '../../../shared/modal/modal.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-admin-placement-test-archived',
  templateUrl: './admin-placement-test-archived.component.html',
  styleUrls: ['./admin-placement-test-archived.component.css']
})
export class AdminPlacementTestArchivedComponent implements OnInit {

  filter = new PlacementTestFilter();
  placementTests: PlacementTestModel[] = [];
  hasMoreData = false;

  countryDropdownList = CountryModel.privateDropdownList(true);

  constructor(private placementSv: AdminPlacementTestService,
              private modalSv: ModalService,
              private router: Router) { }

  ngOnInit() {

    this.filter.limit = 20;
    this.filter.sort = 1;
    this.filter.isArchived = true;

    this.fetchPlacementTests();

  }

  fetchPlacementTests() {

    this.filter.skip = this.placementTests.length;
    this.placementSv.fetchPlacementTests(this.filter).subscribe((placements) => {

      this.hasMoreData = placements.length === this.filter.limit;

      for (const placement of placements) {
        if (this.placementTests.filter((p) => p._id === placement._id).length === 0) {
          this.placementTests.push(placement);
        }
      }

    });

  }

  searchFilteredPlacementTests(name: string, country: string) {

    this.filter.name = name;
    this.filter.country = country;
    this.placementTests = [];

    this.fetchPlacementTests();

  }

  onPlacementTestClicked(placement: PlacementTestModel) {
    this.router.navigate(['admin', 'placementTests', placement._id, 'detail', 'structure']);
  }

  onSortChanged(sort: EVOPlacementTestListSort) {

    this.filter.sort = sort.value;
    this.filter.skip = 0;
    this.placementTests = [];

    this.fetchPlacementTests();

  }

  viewLivePlacementTests() {
    this.router.navigate(['admin', 'placementTests']);
  }

}
