import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PlacementTestModel} from '../../models/edu-admin/placement-test.model';
import {CountryModel} from '../../models/localization/country.model';
import {EVOListViewColumn} from '../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-placement-tests-list-view',
  templateUrl: './evo-placement-tests-list-view.component.html',
  styleUrls: ['./evo-placement-tests-list-view.component.css']
})
export class EvoPlacementTestsListViewComponent implements OnInit {

  CountryModel = CountryModel;

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() placementTests: PlacementTestModel[] = [];

  allSelected = false;
  @Input() selectedPlacementTests: PlacementTestModel[] = [];

  @Input() hasMoreData = false;

  @Output() placementTestsSelected = new EventEmitter<PlacementTestModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOPlacementTestListSort();
  @Output() sortChanged = new EventEmitter<EVOPlacementTestListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() gradeColumn = EVOListViewColumn.smallFixedColumn('grade');
  @Input() countryColumn = EVOListViewColumn.smallFixedColumn('country');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() placementClickEnabled = false;
  @Output() placementClicked = new EventEmitter<PlacementTestModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor() { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedPlacementTests = this.placementTests;
    } else {
      this.selectedPlacementTests = [];
    }

    this.allSelected = selection;

    if (this.placementTestsSelected) {
      this.placementTestsSelected.emit(this.selectedPlacementTests);
    }

  }

  onPlacementTestSelected(placementTest: PlacementTestModel) {

    const index = this.selectedPlacementTests.indexOf(placementTest);
    if (index !== -1) {
      this.selectedPlacementTests.splice(index, 1);
    } else {
      this.selectedPlacementTests.push(placementTest);
    }

    if (this.placementTestsSelected) {
      this.placementTestsSelected.emit(this.selectedPlacementTests);
    }

  }

  hasSelectedPlacementTest(placement: PlacementTestModel): boolean {

    return this.selectedPlacementTests.includes(placement);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onPlacementTestClicked(placement: PlacementTestModel) {

    if (this.placementClickEnabled === true && this.placementClicked) {
      this.placementClicked.emit(placement);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [
      this.selectionColumn,
      this.nameColumn,
      this.gradeColumn,
      this.countryColumn,
      this.createdAtColumn
    ];

  }

}

export class EVOPlacementTestListSort {
  /**
   * The key to use for sorting
   */
  key = 'name';
  /**
   * Should be 1 or -1 for ascending or descending
   */
  value = 1;
}
