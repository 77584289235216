import {DateExtensionModel} from '../extensions/date-extension.model';
import {StringExtensionModel} from '../extensions/string-extension.model';
import {TokenModel} from './token.model';
import {CountryModel} from '../localization/country.model';
import {CultureModel} from '../localization/culture.model';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../shared/modal/modal.service';
import {SchoolModel} from '../school/school.model';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';
import {StudentModel} from './student.model';
import {ErrorModel} from '../shared/error.model';

export class UserModel {

  private static currentUser: UserModel = null;
  private static currentAdminCountry: string = null;
  private static storageKey = 'user';
  private static clientIdStorageKey = 'clientId';
  private static adminCountryStorageKey = 'adminCountry';

  public static SchoolAccessTypes = {
    teacher: 'teacher',
    admin: 'admin',
    list: function() {
      return [this.teacher, this.admin];
    }
  };

  public static SchoolAccessTypesDropdown = [{
    key: 'teacher',
    iconURL: null,
    value: 'schoolAccessTypes.teacher'
  }, {
    key: 'admin',
    iconURL: null,
    value: 'schoolAccessTypes.admin'
  }];

  public _id: string;
  public schoolId: string;
  public firstName: string;
  public lastName: string;
  public email: string;
  public culture: string;
  public country: string;
  public phone: string;

  public password: string;

  public isEmailVerified: boolean;
  public isAdmin: boolean;

  public createdAt: Date;
  public updatedAt: Date;

  public schoolAccess: { schoolId: string, accessType: string }[] = [];

  static generate(json: any): UserModel {

    const user = new UserModel();
    user._id = json._id;
    user.schoolId = json.schoolId;
    user.firstName = json.firstName;
    user.lastName = json.lastName;
    user.email = json.email;
    user.culture = json.culture;
    user.country = json.country;
    user.phone = json.phone;

    user.isEmailVerified = json.isEmailVerified;
    user.isAdmin = json.isAdmin;

    user.createdAt = DateExtensionModel.date(json.createdAt);
    user.updatedAt = DateExtensionModel.date(json.updatedAt);

    if (json.schoolAccess instanceof Array) {
      user.schoolAccess = json.schoolAccess;
    }

    return user;

  }

  static generateList(jsonList: any): UserModel[] {

    const list = [];

    for (const json of jsonList) {
      list.push(UserModel.generate(json));
    }

    return list;

  }

  /**
   * @description Gets the currently cached user, if none is cached, we check if there is one
   * in the local storage and create a user instance from the stored json. Otherwise we return null.
   */
  static getCurrent(): UserModel {

    if (this.currentUser) {
      return this.currentUser;
    }

    const currentUserJson = localStorage.getItem(this.storageKey);
    if (currentUserJson) {
      this.currentUser = this.generate(JSON.parse(currentUserJson));
      return this.currentUser;
    }

    return null;
  }

  /**
   * @description Updates the current user who is logged in and making requests.
   *
   * @param user the new user object.
   */
  static setCurrent(user: UserModel) {

    this.currentUser = user;

    if (user) {
      localStorage.setItem(this.storageKey, JSON.stringify(user));
    } else {
      this.removeCurrent();
    }

  }

  /**
   * @description Removes the currently cached user who is logged in.
   */
  static removeCurrent() {
    this.currentUser = null;
    localStorage.removeItem(this.storageKey);
  }

  /**
   * @description Creates a clientId for the web if none exists or sends
   * a new one if none currently exists and stores it in local storage.
   */
  static getClientId(): string {

    let clientId = localStorage.getItem(this.clientIdStorageKey);
    if (clientId === null || clientId === undefined) {
      // Create a new client Id
      clientId = StringExtensionModel.randomString(12);
      localStorage.setItem(this.clientIdStorageKey, clientId);
    }

    return clientId;

  }

  /**
   * @description A method which indicates whether a current user exists and tokens which can be refreshed
   * so that the user can log into the dashboard.
   */
  static isLoggedIn(): boolean {

    if (TokenModel.getCurrent() && UserModel.getCurrent()) {

      if (TokenModel.getCurrent().isRefreshTokenExpired() === false) {
        return true;
      }

    }

    return false;
  }

  static getAdminCountry(): string {

    if (UserModel.currentAdminCountry) {

      return UserModel.currentAdminCountry;
    }

    const adminCountry = localStorage.getItem(UserModel.adminCountryStorageKey);
    if (adminCountry === null || adminCountry === undefined) {
      UserModel.currentAdminCountry = CountryModel.ISL;
    } else {
      UserModel.currentAdminCountry = adminCountry;
    }

    return UserModel.currentAdminCountry;

  }

  static setAdminCountry(country: string) {

    if (country) {
      UserModel.currentAdminCountry = country;
      localStorage.setItem(this.adminCountryStorageKey, country);
    } else {
      this.removeAdminCountry();
    }

  }

  static removeAdminCountry() {

    localStorage.removeItem(this.adminCountryStorageKey);

  }

  static passwordErraenous(password: string,
                               confirmPassword: string,
                               modalSv: ModalService,
                               tSv: TranslateService): boolean {

    if (password !== confirmPassword) {
      // Show an error

      modalSv.showErrorModal(tSv.instant('reusable.error'),
        tSv.instant('signup.passwordsDoNotMatch'),
        tSv.instant('reusable.done'));
      return true;

    } else if (password === null || password === '') {

      modalSv.showErrorModal(tSv.instant('reusable.error'),
        tSv.instant('signup.passwordMissing'),
        tSv.instant('reusable.done'));
      return true;

    } else if (password.length < 7) {

      // Password needs to be 7 characters or longer
      modalSv.showErrorModal(tSv.instant('reusable.error'),
        tSv.instant('signup.passwordNotLongEnough'),
        tSv.instant('reusable.done'));
      return true;

    } else if (password.match(new RegExp(/[A-Za-z]/)) === null) {

      modalSv.showErrorModal(tSv.instant('reusable.error'),
        tSv.instant('signup.passwordMustIncludeAlpha'),
        tSv.instant('reusable.done'));
      return true;

    } else if (password.match(new RegExp(/[0-9]/)) === null) {

      modalSv.showErrorModal(tSv.instant('reusable.error'),
        tSv.instant('signup.passwordMustIncludeNumeric'),
        tSv.instant('reusable.done'));
      return true;

    }

    return false;

  }

  /**
   * As we have login in several places thi is a unified login method to setup
   * Reusable logic for navigation, cached models, amongst other things.
   *
   * @param email email of the user.
   * @param password password of the user.
   * @param authSv authentication service to send alogin request to the server.
   * @param tSv Translation service to translate error messages.
   * @param modalSv modal service to show error messages.
   * @param router Router to route the login appropriately.
   */
  static signInUser(email: string, password: string, authSv: AuthService, tSv: TranslateService, modalSv: ModalService, router: Router) {

    authSv.signIn(email, password).subscribe((response) => {

      StudentModel.setCurrent(null);
      SchoolModel.setCurrent(null);
      CultureModel.setHomepageCulture(response.user.culture);
      CountryModel.setHomepageCountry(response.user.country);
      tSv.use(response.user.culture);

      if (UserModel.getCurrent().isEmailVerified === false) {
        router.navigate(['home/' + CultureModel.getHomepageCulture(), 'verifyEmail']);
      } else if (UserModel.getCurrent().hasSchoolAccess()) {
        router.navigate(['selectschool']);
      } else {
        router.navigate(['selectstudent']);
      }

    }, (error: ErrorModel) => {

      if (error.name === 'ObjectNotFoundError' || error.name === 'PasswordIncorrect') {
        modalSv.showErrorModal(tSv.instant('reusable.error'),
          tSv.instant('signin.signinWrong'),
          tSv.instant('reusable.done'));
      } else {
        modalSv.showErrorModal(tSv.instant('reusable.error'),
          tSv.instant('reusable.unknownError'),
          tSv.instant('reusable.done'));
      }

    });

  }


  hasSchoolAdminAccess(schoolId: string) {

    if (this.schoolAccess && this.schoolAccess.length > 0) {

      for (const access of this.schoolAccess) {

        if (access.schoolId === schoolId && access.accessType === UserModel.SchoolAccessTypes.admin) {

          return true;
        }
      }

    }

    return false;

  }

  findSchoolAccessType(schoolId: string): string {

    if (this.schoolAccess && this.schoolAccess.length > 0 && schoolId) {

      for (const access of this.schoolAccess) {
        if (access.schoolId === schoolId) {
          return access.accessType;
        }
      }

    }

    return null;

  }

  setSchoolAccessType(accessType: string, schoolId: string) {

    if (this.schoolAccess && this.schoolAccess.length > 0 && schoolId) {

      for (const access of this.schoolAccess) {
        if (access.schoolId === schoolId) {
          access.accessType = accessType;
        }
      }

    }

  }

  hasSchoolAccess(): boolean {

    if (this.schoolAccess.length > 0 || this.schoolId) {
      return true;
    }

    return false;
  }

  getFullName(): string {

    let fullName = '';
    if (this.firstName) {
      fullName += this.firstName;
      fullName += ' ';
    }

    if (this.lastName) {
      fullName += this.lastName;
    }

    return fullName;

  }

  getTwoInitials(): string {

    if (!this.firstName || !this.lastName) {
      return '';
    }

    return this.firstName.substring(0, 1).toUpperCase() + this.lastName.substring(0, 1).toUpperCase();

  }

  getAccountType(tSv: TranslateService, schoolId: string) {

    if (this.schoolAccess && this.schoolAccess.length > 0) {

      if (schoolId != null) {

        const accessType = this.findSchoolAccessType(schoolId);
        if (accessType != null) {
          return tSv.instant('schoolAccessTypes.' + accessType);
        }

      }

      return tSv.instant('schoolAccessTypes.teacher');

    }

    return tSv.instant('schoolAccessTypes.consumer');

  }

  getCountryName(): string {

    return CountryModel.title(this.country);

  }

}

export class UserFilter {

  skip = 0;
  limit: number = undefined;

  name: string = undefined;
  email: string = undefined;

  country: string = undefined;

  /**
   * Uses the name to sort ascending or descending, 1 or -1.
   */
  sort = 1;

}

export class TeacherInvitation {

  email: string;
  firstName: string;
  lastName: string;
  schoolId: string;
  schoolAccessType: string;

}
