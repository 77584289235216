import {CountryBasedNumberModel, QuestionModel} from './question.model';
import {CountNumbersModel} from './subModels/countNumbers.model';
import {CountMultipleNumbersModel} from './subModels/countMultipleNumbers.model';
import {SingleAdditionModel} from './subModels/singleAddition.model';
import {SingleAdditionNeededAmountModel} from './subModels/singleAdditionNeededAmount.model';
import {DoubleAdditionModel} from './subModels/doubleAddition.model';
import {SingleSubtractionModel} from './subModels/singleSubtraction.model';
import {SingleSubtractionNeededAmountModel} from './subModels/singleSubtractionNeededAmount.model';
import {DoubleSubtractionModel} from './subModels/doubleSubtraction.model';
import {DescriptionModel} from '../shared/description.model';
import {SizeComparisonNumbersModel} from './subModels/sizeComparisonNumbers.model';
import {SizeComparisonSymbolsModel} from './subModels/sizeComparisonSymbols.model';
import {SizeComparisonWordsModel} from './subModels/sizeComparisonWords.model';
import {CountShapesModel} from './subModels/countShapes.model';
import {ShapeNameFromImageModel} from './subModels/shapeNameFromImage.model';
import {ShapeSingleDiscriminationCountModel} from './subModels/shapeSingleDiscriminationCount.model';
import {ImagePatternSingleFillModel} from './subModels/imagePatternSingleFill.model';
import {NumberPatternSingleFillModel} from './subModels/numberPatternSingleFill.model';
import {RepeatedAdditionModel} from './subModels/repeatedAddition.model';
import {UnitHouseModel} from './subModels/unitHouse.model';
import {SingleMultiplicationModel} from './subModels/singleMultiplication.model';
import {SingleDivisionModel} from './subModels/singleDivision.model';
import {CornerNameFromImageModel} from './subModels/cornerNameFromImage.model';
import {FractionSetupFromImageModel} from './subModels/fractionSetupFromImage.model';
import {ProportionsSetupFromImageModel} from './subModels/proportionsSetupFromImage.model';
import {Translation2DModel} from './subModels/translation2D.model';
import {PerimeterFromShapeModel} from './subModels/perimeterFromShapeModel';
import {AreaFromShapeModel} from './subModels/areaFromShape.model';
import {RoundingModel} from './subModels/rounding.model';
import {BarChartModel} from './subModels/barChart.model';

export function generateQuestion(json: any): QuestionModel {

  let q = null;
  const subCat = json.subCategory;
  if (subCat === QuestionModel.SubCategoryTypes.cornerNameFromImage) {
    q = CornerNameFromImageModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.countNumbers) {
    q = CountNumbersModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.countMultipleNumbers) {
    q = CountMultipleNumbersModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.fractionSetupFromImage) {
    q = FractionSetupFromImageModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {
    q = ProportionsSetupFromImageModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.translation2D) {
    q = Translation2DModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.singleAddition) {
    q = SingleAdditionModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.singleAdditionNeededAmount) {
    q = SingleAdditionNeededAmountModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.doubleAddition) {
    q = DoubleAdditionModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.singleSubtraction) {
    q = SingleSubtractionModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.singleSubtractionNeededAmount) {
    q = SingleSubtractionNeededAmountModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.doubleSubtraction) {
    q = DoubleSubtractionModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.sizeComparisonNumbers) {
    q = SizeComparisonNumbersModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.sizeComparisonSymbols) {
    q = SizeComparisonSymbolsModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.sizeComparisonWords) {
    q = SizeComparisonWordsModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.countShapes) {
    q = CountShapesModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.shapeNameFromImage) {
    q = ShapeNameFromImageModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.shapeSingleDiscriminationCount) {
    q = ShapeSingleDiscriminationCountModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.imagePatternSingleFill) {
    q = ImagePatternSingleFillModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.numberPatternSingleFill) {
    q = NumberPatternSingleFillModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.repeatedAddition) {
    q = RepeatedAdditionModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.singleMultiplication) {
    q = SingleMultiplicationModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.unitHouse) {
    q = UnitHouseModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.singleDivision) {
    q = SingleDivisionModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.perimeterFromShape) {
    q = PerimeterFromShapeModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.areaFromShape) {
    q = AreaFromShapeModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.roundingWholeNumber) {
    q = RoundingModel.generateModel(json);
  } else if (subCat === QuestionModel.SubCategoryTypes.barChart) {
    q = BarChartModel.generateModel(json);
  } else {
    q = new QuestionModel();
  }

  q._id = json._id;

  q.name = json.name;
  q.subject = json.subject;
  q.topic = json.topic;
  q.category = json.category;
  q.subCategory = json.subCategory;
  q.country = json.country;
  q.isStarterQuestion = json.isStarterQuestion;
  q.isArchived = json.isArchived;

  q.answerType = json.answerType;
  q.representation = json.representation;

  q.isPublished = json.isPublished;

  q.majorVersion = json.majorVersion;
  q.minorVersion = json.minorVersion;
  q.revisionVersion = json.revisionVersion;

  q.decidedRank = json.decidedRank;
  q.rank = json.rank;
  q.numCorrect = json.numCorrect;
  q.numWrong = json.numWrong;

  if (json.createdAt) {
    q.createdAt = new Date(json.createdAt);
  }

  if (json.updatedAt) {
    q.updatedAt = new Date(json.updatedAt);
  }

  if (json.descriptions) {
    q.descriptions = DescriptionModel.generateModel(json.descriptions);
  }

  if (json.videoHelp) {
    q.videoHelp = DescriptionModel.generateModel(json.videoHelp);
  }

  if (json.audioHelp) {
    q.audioHelp = DescriptionModel.generateModel(json.audioHelp);
  }

  if (json.rankCountry) {
    q.rankCountry = CountryBasedNumberModel.generate(json.rankCountry);
  }

  if (json.numCorrectCountry) {
    q.numCorrectCountry = CountryBasedNumberModel.generate(json.numCorrectCountry);
  }

  if (json.numWrongCountry) {
    q.numWrongCountry = CountryBasedNumberModel.generate(json.numWrongCountry);
  }


  return q;
}

export function generateQuestionList(jsonList: any[]): QuestionModel[] {

  const list = [];

  for (const json of jsonList) {
    const q = generateQuestion(json);
    list.push(q);
  }

  return list;

}

export function questionFromSubcategory(topic: string, category: string, subCat: string): QuestionModel {

  let q = new QuestionModel();

  if (subCat === QuestionModel.SubCategoryTypes.cornerNameFromImage) {
    q = new CornerNameFromImageModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.countNumbers) {
    q = new CountNumbersModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.countMultipleNumbers) {
    q = new CountMultipleNumbersModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.fractionSetupFromImage) {
    q = new FractionSetupFromImageModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {
    q = new ProportionsSetupFromImageModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.translation2D) {
    q = new Translation2DModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.singleAddition) {
    q = new SingleAdditionModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.singleAdditionNeededAmount) {
    q = new SingleAdditionNeededAmountModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.doubleAddition) {
    q = new DoubleAdditionModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.singleSubtraction) {
    q = new SingleSubtractionModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.singleSubtractionNeededAmount) {
    q = new SingleSubtractionNeededAmountModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.doubleSubtraction) {
    q = new DoubleSubtractionModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.sizeComparisonNumbers) {
    q = new SizeComparisonNumbersModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.sizeComparisonSymbols) {
    q = new SizeComparisonSymbolsModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.sizeComparisonWords) {
    q = new SizeComparisonWordsModel();
  }  else if (subCat === QuestionModel.SubCategoryTypes.countShapes) {
    q = new CountShapesModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.shapeNameFromImage) {
    q = new ShapeNameFromImageModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.shapeSingleDiscriminationCount) {
    q = new ShapeSingleDiscriminationCountModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.imagePatternSingleFill) {
    q = new ImagePatternSingleFillModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.numberPatternSingleFill) {
    q = new NumberPatternSingleFillModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.repeatedAddition) {
    q = new RepeatedAdditionModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.singleMultiplication) {
    q = new SingleMultiplicationModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.unitHouse) {
    q = new UnitHouseModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.singleDivision) {
    q = new SingleDivisionModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.perimeterFromShape) {
    q = new PerimeterFromShapeModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.areaFromShape) {
    q = new AreaFromShapeModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.roundingWholeNumber) {
    q = new RoundingModel();
  } else if (subCat === QuestionModel.SubCategoryTypes.barChart) {
    q = new BarChartModel();
  }

  q.topic = topic;
  q.category = category;
  q.subCategory = subCat;

  return q;

}
