import { Component, OnInit } from '@angular/core';
import {UserModel} from '../../../../models/authentication/user.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ModalService} from '../../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {EVOTeacherListSort} from '../../../../shared/evo-teacher-list-view/evo-teacher-list-view.component';
import {AdminSchoolTeacherFilter, AdminSchoolUserService} from '../../../../services/admin/admin-school-user.service';
import {SchoolModel} from '../../../../models/school/school.model';
import {AdminSchoolService} from '../../../../services/admin/admin-school.service';
import {CountryModel} from '../../../../models/localization/country.model';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {ErrorModel} from '../../../../models/shared/error.model';

@Component({
  selector: 'app-evo-admin-school-teachers',
  templateUrl: './evo-admin-school-teachers.component.html',
  styleUrls: ['./evo-admin-school-teachers.component.css']
})
export class EvoAdminSchoolTeachersComponent implements OnInit {

  schoolId: string;
  teachers: UserModel[] = [];
  filter = new AdminSchoolTeacherFilter();
  hasMoreData = false;

  school: SchoolModel;
  showShareWindow = false;
  isCreatingNewLink = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private schoolSv: AdminSchoolService,
              private adminSv: AdminSchoolUserService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      this.fetchTeachersForGroup();
      this.fetchSchool();

    });

  }

  fetchSchool() {

    this.schoolSv.fetchSchoolById(this.schoolId).subscribe((school) => {
      this.school = school;
    });

  }

  // Get all the students in the group
  fetchTeachersForGroup() {

    this.filter.skip = this.teachers.length;
    this.adminSv.fetchSchoolUsers(this.schoolId, this.filter).subscribe((teachers) => {

      this.hasMoreData = teachers.length === this.filter.limit;
      for (const teacher of teachers) {
        if (this.teachers.filter((t) => t._id === teacher._id).length === 0) {
          this.teachers.push(teacher);
        }
      }

    });
  }

  onSearchTeachers(nameOrEmail: string) {

    if (nameOrEmail === '' || !nameOrEmail) {
      this.filter.name = null;
      this.filter.email = null;
    } else {
      this.filter.name = nameOrEmail;
      this.filter.email = nameOrEmail;
    }

    this.teachers = [];
    this.fetchTeachersForGroup();

  }

  onSortChanged(sort: EVOTeacherListSort) {

    this.filter.sort = sort.value;

    this.teachers = [];
    this.fetchTeachersForGroup();

  }

  onImportTeachersClicked() {
    this.router.navigate(['admin', 'schools', this.schoolId, 'invitations', 'import']);
  }

  onInvitationsClicked() {
    this.router.navigate(['admin', 'schools', this.schoolId, 'invitations']);
  }

  onShareClicked() {
    this.showShareWindow = true;
  }

  onTeacherClicked(teacher: UserModel) {
    this.router.navigate(['admin', 'schools', this.schoolId, 'users', teacher._id, 'students']);
  }

  getLink(): string {

    return CountryModel.getTeacherInvitationLinkForCountry(this.school.country, this.school.linkInvitationId);

  }

  onLinkCopyClicked() {

    StringExtensionModel.copyStringToClipboard(this.getLink());
    this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
      this.tSv.instant('reusable.copySuccess')).subscribe();

  }

  onNewLinkClicked() {

    this.isCreatingNewLink = true;
    this.schoolSv.generateNewLink(this.school._id).subscribe((school) => {
      this.school = school;
      this.isCreatingNewLink = false;
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.successMessage'));
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onLinkInvitationEnableChanged(value: boolean) {

    if (!this.school.linkInvitationId) {

      this.schoolSv.generateNewLink(this.school._id).subscribe((s1) => {

        this.schoolSv.enableLinkInvitation(this.school._id, value).subscribe((s2) => {
          this.school = s2;
        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });

      }, (err: ErrorModel) => {
        this.modalSv.showTranslatedErrorModal(err);
      });

    } else {

      this.schoolSv.enableLinkInvitation(this.school._id, value).subscribe((s) => {
        this.school = s;
      }, (err: Error) => {
        this.modalSv.showTranslatedErrorModal(err);
      });

    }

  }

}
