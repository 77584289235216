import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SchoolModel} from '../../../../models/school/school.model';
import {AdminSchoolService} from '../../../../services/admin/admin-school.service';
import {CountryModel} from '../../../../models/localization/country.model';
import {CultureModel} from '../../../../models/localization/culture.model';
import {ModalService} from '../../../../shared/modal/modal.service';
import {ErrorModel} from '../../../../models/shared/error.model';

@Component({
  selector: 'app-evo-admin-school-settings',
  templateUrl: './evo-admin-school-settings.component.html',
  styleUrls: ['./evo-admin-school-settings.component.css']
})
export class EvoAdminSchoolSettingsComponent implements OnInit {

  schoolId: string;
  school: SchoolModel;
  Countries = CountryModel.privateDropdownList();
  Cultures = CultureModel.privateDropdownList();

  updatingSchool = false;
  deletingSchool = false;

  excludeBusinessMetrics: boolean;
  isTestSchool;

  password = '';
  secret = '';

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private schoolSv: AdminSchoolService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      this.fetchSchool();

    });

  }

  fetchSchool() {

    this.schoolSv.fetchSchoolById(this.schoolId).subscribe((school) => {
      this.school = school;
      this.excludeBusinessMetrics = school.excludeFromBusinessMetrics;
      this.isTestSchool = school.isTestSchool;
    });

  }

  onSaveClicked() {

    this.updatingSchool = true;
    this.schoolSv.updateSchool(this.school).subscribe((school) => {
      this.updatingSchool = false;
      this.modalSv.showAlertModal('Success', 'School update successfully');
    }, (err: ErrorModel) => {
      this.updatingSchool = false;
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onExcludeBusinessMetricsClicked() {

    this.school.excludeFromBusinessMetrics = this.excludeBusinessMetrics;
    this.onSaveClicked();

  }

  onIsTestSchoolClicked() {

    this.school.isTestSchool = this.isTestSchool;
    if (this.isTestSchool) {
      this.school.excludeFromBusinessMetrics = true;
    }

    this.onSaveClicked();

  }

  onDeleteClicked() {

    this.deletingSchool = true;
    this.modalSv.showChoiceModal('Warning', `Are you sure you want to delete ${this.school.name}`).subscribe((success) => {

      if (success) {
        this.schoolSv.deleteSchool(this.school._id, this.password, this.secret).subscribe((school) => {
          this.deletingSchool = false;
          this.modalSv.showAlertModal('Success', 'School deletion successful').subscribe(() => {
            this.router.navigate(['admin', 'schools']);
          });

        }, (err: ErrorModel) => {
          this.deletingSchool = false;
          this.modalSv.showTranslatedErrorModal(err);
        });
      }

    });

  }

}
