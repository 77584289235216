import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ModalService} from '../../../../shared/modal/modal.service';
import {AdminPlacementTestService} from '../../../../services/admin/admin-placement-test.service';
import {PlacementTestModel} from '../../../../models/edu-admin/placement-test.model';
import {AdminQuestionSelectionListService} from '../../../shared/admin-question-selection-list/admin-question-selection-list.service';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {ErrorModel} from '../../../../models/shared/error.model';
import {QuestionModel} from '../../../../models/questions/question.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-admin-placement-test-requisite-skills',
  templateUrl: './admin-placement-test-requisite-skills.component.html',
  styleUrls: ['./admin-placement-test-requisite-skills.component.css']
})
export class AdminPlacementTestRequisiteSkillsComponent implements OnInit {

  placementTestId: string;
  placementTest: PlacementTestModel;

  questionsToRemove: QuestionModel[] = [];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private questionSelectSv: AdminQuestionSelectionListService,
              private placementSv: AdminPlacementTestService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.placementTestId = params.testId;
      this.fetchPlacementTest();

    });

  }

  fetchPlacementTest() {

    this.placementSv.fetchPlacementTestById(this.placementTestId, true).subscribe((placementTest) => {

      this.placementTest = placementTest;

    });

  }

  onSelectPreRequisiteSkill() {

    const idsToExclude = StringExtensionModel.queryArrayString(this.placementTest.getQuestionIdsInUse());

    this.questionSelectSv.show(idsToExclude).subscribe((questions) => {

      if (questions && questions.length > 0) {
        for (const q of questions) {
          this.placementTest.preRequisiteSkillIds.push(q._id);
          this.placementTest.preRequisiteSkills.push(q);
        }

        this.placementSv.updatePlacementTest(this.placementTest).subscribe((placement) => {
          this.fetchPlacementTest();
          this.modalSv.showAlertModal('Success', 'You have successfully updated the skill ids for the placement test.');
        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });
      }

    });

  }

  onRemovePreRequisiteSkills() {

    this.modalSv.showChoiceModal(this.tSv.instant('reusable.warning'), 'Are you sure you want to delete these skills?').subscribe((show) => {

      if (show) {

        for (const q of this.questionsToRemove) {

          const index = this.placementTest.preRequisiteSkillIds.indexOf(q._id);
          if (index !== -1) {
            this.placementTest.preRequisiteSkillIds.splice(index, 1);
            this.placementTest.preRequisiteSkills.splice(index, 1);
          }

        }

        this.placementSv.updatePlacementTest(this.placementTest).subscribe((placement) => {
          this.fetchPlacementTest();
          this.questionsToRemove = [];
          this.modalSv.showAlertModal('Success', 'You have successfully updated the skill ids for the placement test.');
        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });

      }

    });

  }

}
