import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ObjectiveModel} from '../../models/edu-admin/objective.model';
import {CountryModel} from '../../models/localization/country.model';
import {EVOListViewColumn} from '../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-objective-list-view',
  templateUrl: './evo-objective-list-view.component.html',
  styleUrls: ['./evo-objective-list-view.component.css']
})
export class EvoObjectiveListViewComponent implements OnInit {

  CountryModel = CountryModel;

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() objectives: ObjectiveModel[] = [];

  allSelected = false;
  @Input() selectedObjectives: ObjectiveModel[] = [];

  @Input() hasMoreData = false;

  @Output() objectivesSelected = new EventEmitter<ObjectiveModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOObjectiveListSort();
  @Output() sortChanged = new EventEmitter<EVOObjectiveListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() gradeColumn = EVOListViewColumn.smallFixedColumn('grade');
  @Input() countryColumn = EVOListViewColumn.smallFixedColumn('country');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() objectiveClickEnabled = false;
  @Output() objectiveClicked = new EventEmitter<ObjectiveModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor() { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedObjectives = this.objectives;
    } else {
      this.selectedObjectives = [];
    }

    this.allSelected = selection;

    if (this.objectivesSelected) {
      this.objectivesSelected.emit(this.selectedObjectives);
    }

  }

  onObjectiveSelected(objective: ObjectiveModel) {

    const index = this.selectedObjectives.indexOf(objective);
    if (index !== -1) {
      this.selectedObjectives.splice(index, 1);
    } else {
      this.selectedObjectives.push(objective);
    }

    if (this.objectivesSelected) {
      this.objectivesSelected.emit(this.selectedObjectives);
    }

  }

  hasSelectedObjective(objective: ObjectiveModel): boolean {

    return this.selectedObjectives.includes(objective);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onObjectiveClicked(objective: ObjectiveModel) {

    if (this.objectiveClickEnabled === true && this.objectiveClicked) {
      this.objectiveClicked.emit(objective);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [
      this.selectionColumn,
      this.nameColumn,
      this.gradeColumn,
      this.countryColumn,
      this.createdAtColumn
    ];

  }

}

export class EVOObjectiveListSort {
  /**
   * The key to use for sorting
   */
  key = 'name';
  /**
   * Should be 1 or -1 for ascending or descending
   */
  value = 1;
}
