import { Component, OnInit } from '@angular/core';
import {EVOSegmentedControlItem} from '../../../shared/evo-segmented-control/evo-segmented-control.component';
import {PlacementTestModel} from '../../../models/edu-admin/placement-test.model';
import {AdminPlacementTestService} from '../../../services/admin/admin-placement-test.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-admin-placement-test-edit',
  templateUrl: './admin-placement-test-edit.component.html',
  styleUrls: ['./admin-placement-test-edit.component.css']
})
export class AdminPlacementTestEditComponent implements OnInit {

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('structure', 'admin.placementTestStructureTitle'),
    new EVOSegmentedControlItem('preRequisite', 'admin.preRequisiteSkills'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem = this.controls[0];

  placementTest: PlacementTestModel;

  constructor(private placementTestSv: AdminPlacementTestService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    for (const item of this.controls) {
      if (item.key === page) {
        this.currentItem = item;
      }
    }

    this.route.params.subscribe((params: Params) => {

      this.placementTestSv.fetchPlacementTestById(params.testId).subscribe((placement) => {

        this.placementTest = placement;

      });

    });

  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.currentItem = item;
    this.router.navigate(['admin', 'placementTests', this.placementTest._id, 'detail', item.key]);
  }

}
