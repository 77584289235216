import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {GroupModel, GroupQueryModel} from '../../../models/school/group.model';
import {SchoolAdminGroupService} from '../../../services/school/school-admin-group.service';
import {EVOGroupListSort} from '../../../shared/evo-groups-list-view/evo-groups-list-view.component';
import {EvoCreateGroupModalService} from '../../../shared/evo-create-group-modal/evo-create-group-modal.service';
import {ModalService} from '../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-school-school-groups',
  templateUrl: './school-admin-groups.component.html',
  styleUrls: ['./school-admin-groups.component.css']
})
export class SchoolAdminGroupsComponent implements OnInit {

  groups: GroupModel[] = [];

  hasMoreGroups = true;
  filter = new GroupQueryModel();

  group = new GroupModel();

  constructor(private router: Router,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private createGroupModalSv: EvoCreateGroupModalService,
              private groupSv: SchoolAdminGroupService) {
  }

  ngOnInit() {
    this.filter.limit = 20;
    this.filter.sort = -1;
    this.fetchGroups();
  }

  fetchGroups() {

    this.filter.skip = this.groups.length;
    this.groupSv.fetchGroups(this.filter).subscribe((groups) => {

      this.hasMoreGroups = groups.length === this.filter.limit;
      this.groups = groups;

    });

  }

  searchFilteredGroups(searchString: string) {

    if (searchString !== null && searchString.length > 0) {
      this.filter.name = searchString;
    } else {
      this.filter.name = undefined;
    }

    this.groups = [];

    this.fetchGroups();

  }

  onGroupClicked(group: GroupModel) {
    this.router.navigate(['schooladmin', 'groups', group._id, 'students']);
  }

  showCreateGroupClicked() {

    this.createGroupModalSv.show().subscribe((group) => {

      // show a confirmation message
      const title = this.tSv.instant('reusable.success');
      const message = this.tSv.instant('reusable.successMessage');

      this.modalSv.showAlertModal(title, message).subscribe(() => {
        this.router.navigate(['schooladmin', 'groups', group._id, 'students']);
      });

    });

  }

  onSortChanged(sort: EVOGroupListSort) {

    this.filter.sort = sort.value;
    this.filter.skip = 0;
    this.groups = [];

    this.fetchGroups();

  }

}
