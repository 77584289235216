import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {MultipleImageTypesModel} from '../../../../../models/questions/shared/multipleImageTypes.model';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {
  EvoAbilityIconImages,
  EvoItemImages,
  EvoMonsterImages,
  ImageCategoryTypes
} from '../../../../../models/questions/shared/imageType.model';
import {EvoListSelectorService} from '../../../../../shared/evo-list-selector/evo-list-selector.service';

@Component({
  selector: 'app-evo-reusable-multiple-image-types',
  templateUrl: './evo-reusable-multiple-image-types.component.html',
  styleUrls: ['./evo-reusable-multiple-image-types.component.css'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => EvoReusableMultipleImageTypesComponent),
    multi: true
  }]
})
export class EvoReusableMultipleImageTypesComponent implements OnInit {

  ImageCategoryTypes = ImageCategoryTypes;

  @Input() title = '';
  @Input() images: MultipleImageTypesModel = new MultipleImageTypesModel();

  propagateChange = (_: any) => {};

  constructor(private listSelectSv: EvoListSelectorService) { }

  ngOnInit() {
  }

  categoryChanged(category: string) {

    if (this.images.category !== category) {
      this.images.category = category;
      this.images.images = [];
    }

  }

  addImageClicked() {

    this.listSelectSv.showList(this.getCategoryImages(), this.images.images).subscribe((image) => {
      this.images.images.push(image);
    });

  }

  getCategoryImages() {

    if (this.images) {

      if (this.images.category === ImageCategoryTypes.items) {
        return EvoItemImages.list();
      } else if (this.images.category === ImageCategoryTypes.monsters) {
        return EvoMonsterImages.list();
      } else if (this.images.category === ImageCategoryTypes.monsterThumbnails) {
        return EvoMonsterImages.list();
      } else if (this.images.category === ImageCategoryTypes.abilities) {
        return EvoAbilityIconImages.list();
      }

    }

    return [];

  }

  removeImage(index: number) {
    this.images.images.splice(index, 1);
  }

  getImgUrl(image: string) {

    if (this.images.category === ImageCategoryTypes.items) {
      return EvoItemImages.imgUrl(image);
    } else if (this.images.category === ImageCategoryTypes.monsters) {
      return EvoMonsterImages.imgUrl(image);
    } else if (this.images.category === ImageCategoryTypes.monsterThumbnails) {
      return EvoMonsterImages.imgUrlThumbnail(image);
    } else if (this.images.category === ImageCategoryTypes.abilities) {
      return EvoMonsterImages.imgUrl(image);
    }

    return null;

  };

  writeValue(obj: any): void {

    if (obj !== null &&
      obj !== undefined &&
      obj instanceof MultipleImageTypesModel) {
      this.images = obj;
      this.propagateChange(this.images);
    } else {
      this.propagateChange(this.images);
    }

  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

}
