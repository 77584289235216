import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {StudentModel} from '../../../models/authentication/student.model';
import {SchoolAdminStudentService} from '../../../services/school/school-admin-student.service';
import {SchoolModel} from '../../../models/school/school.model';
import {ModalService} from '../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorModel} from '../../../models/shared/error.model';
import {EVOStudentListSort} from '../../../shared/evo-student-list-view/evo-student-list-view.component';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';

@Component({
  selector: 'app-school-school-students',
  templateUrl: './school-admin-students.component.html',
  styleUrls: ['./school-admin-students.component.css']
})
export class SchoolAdminStudentsComponent implements OnInit {

  showCreateStudent = false;
  showDeleteStudent = false;
  students: StudentModel[] = [];

  showStudentFilter = false;
  hasMoreStudents = true;
  filter = new StudentFilterModel();

  student = new StudentModel();

  fromAge: number;
  toAge: number;


  constructor(private router: Router,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private studentSv: SchoolAdminStudentService) { }

  ngOnInit() {

    if (SchoolModel.getCurrent()) {
      this.student.culture = SchoolModel.getCurrent().culture;
    }

    this.fetchStudents();

  }

  onStudentsDeleted() {

    this.filter.skip = 0;
    this.students = [];
    this.showDeleteStudent = false;
    this.fetchStudents();

  }

  fetchStudents() {

    this.filter.skip = this.students.length;
    this.studentSv.fetchStudents(this.filter).subscribe((students) => {

      this.hasMoreStudents = students.length === this.filter.limit;

      for (const student of students) {
        if (this.students.filter((s) => s._id === student._id).length === 0) {
          this.students.push(student);
        }
      }

    });

  }

  searchFilteredStudents(searchString: string) {

    if (searchString !== null && searchString.length > 0) {
      this.filter.name = searchString;
    } else {
      this.filter.name = undefined;
    }

    this.students = [];

    this.fetchStudents();

  }

  onImportClicked() {
    this.router.navigate(['schooladmin', 'importStudents']);
  }

  onStudentClicked(student: StudentModel) {
    this.router.navigate(['schooladmin', 'students', student._id]);
  }

  closeCreateStudentClicked() {
    this.showCreateStudent = false;
  }

  createStudentClicked() {

    if (!this.student.firstName || this.student.firstName.length < 0) {
      return this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), this.tSv.instant('createStudent.firstNameRequired'));
    }

    this.showCreateStudent = false;
    this.students = [];
    this.filter.skip = 0;

    this.studentSv.createStudent(this.student).subscribe((newStudent) => {
      this.student = new StudentModel();
      this.fetchStudents();
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.success'));
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(ErrorModel.GetError(err.name, this.tSv));
    });
  }

  onFilterChanged(filter: StudentFilterModel) {
    this.filter.skip = 0;
    this.filter = filter;
    this.students = [];
    this.fetchStudents();
  }

  onSortChanged(sort: EVOStudentListSort) {

    this.filter.updateWithListSort(sort);
    this.students = [];
    this.fetchStudents();

  }

}
