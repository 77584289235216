import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StudentModel} from '../../models/authentication/student.model';
import {EVOListViewColumn} from '../../models/UI/EVOListViewColumn.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-evo-student-list-view',
  templateUrl: './evo-student-list-view.component.html',
  styleUrls: ['./evo-student-list-view.component.css']
})
export class EvoStudentListViewComponent implements OnInit {

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() students: StudentModel[] = [];

  allSelected = false;
  @Input() selectedStudents: StudentModel[] = [];

  @Input() hasMoreData = false;

  @Output() studentsSelected = new EventEmitter<StudentModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOStudentListSort();
  @Output() sortChanged = new EventEmitter<EVOStudentListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() birthYearColumn = EVOListViewColumn.smallFixedColumn('birthYear');
  @Input() statusColumn = EVOListViewColumn.smallFixedColumn('status');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() studentClickEnabled = false;
  @Output() studentClicked = new EventEmitter<StudentModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  constructor(public tSv: TranslateService) { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  getLargeScreenAndHigherWidth(): string {

    return '100%';
  }

  getMediumScreenWidth(): string {

    let defaultWidth = 980;
    if (this.selectionEnabled) {
      defaultWidth += 80;
    }

    return defaultWidth.toString() + 'px';
  }

  getSmallScreenAndLowerWidth(): string {

    let defaultWidth = 800;
    if (this.selectionEnabled) {
      defaultWidth += 80;
    }

    return defaultWidth.toString() + 'px';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedStudents = this.students;
    } else {
      this.selectedStudents = [];
    }

    this.allSelected = selection;

    if (this.studentsSelected) {
      this.studentsSelected.emit(this.selectedStudents);
    }

  }

  onStudentSelect(student: StudentModel) {

    const index = this.selectedStudents.indexOf(student);
    if (index !== -1) {
      this.selectedStudents.splice(index, 1);
    } else {
      this.selectedStudents.push(student);
    }

    if (this.studentsSelected) {
      this.studentsSelected.emit(this.selectedStudents);
    }

  }

  hasSelectedStudent(student: StudentModel): boolean {

    if (this.selectedStudents) {
      for (const s of this.selectedStudents) {
        if (s._id === student._id) {
          return true;
        }
      }
    }

    return false;
  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.name === 1) {

      this.sort.name = -1;

    } else if (this.sort.name === -1) {

      this.sort.name = 1;

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onBirthLegendSortclicked() {

    if (this.sort.birthdate === null) {
      this.sort.birthdate = 1;
    } else if (this.sort.birthdate === 1) {
      this.sort.birthdate = -1;
    } else {
      // should catch -1 and all other outlier cases
      this.sort.birthdate = null;
    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onStudentClicked(student: StudentModel) {

    if (this.studentClickEnabled === true && this.studentClicked) {
      this.studentClicked.emit(student);
    }

  }

  getSortArrowImgUrl(sortValue: number): string {

    if (sortValue === 1) {
      return './assets/icons/sort-up.svg';
    } else if (sortValue === -1) {
      return './assets/icons/sort-down.svg';
    } else {
      return null;
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [this.selectionColumn, this.nameColumn, this.birthYearColumn, this.statusColumn, this.createdAtColumn];

  }

}

export class EVOStudentListSort {

  /**
   * The default sort method always -1 or 1. Never null.
   */
  name = 1;

  /**
   * The birthdate sort by default is null no sorting.
   *
   */
  birthdate: number = null;

  createdAt: number = null;

}
