import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GroupModel} from '../../models/school/group.model';
import {EVOListViewColumn} from '../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-groups-list-view',
  templateUrl: './evo-groups-list-view.component.html',
  styleUrls: ['./evo-groups-list-view.component.css']
})
export class EvoGroupsListViewComponent implements OnInit {
  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() groups: GroupModel[] = [];

  allSelected = false;
  @Input() selectedGroups: GroupModel[] = [];

  @Input() hasMoreData = false;

  @Output() groupsSelected = new EventEmitter<GroupModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOGroupListSort();
  @Output() sortChanged = new EventEmitter<EVOGroupListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.customColumn('name', true, 'grow', '300px', null, null, null, null);
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() groupClickEnabled = false;
  @Output() groupClicked = new EventEmitter<GroupModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor() { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedGroups = this.groups;
    } else {
      this.selectedGroups = [];
    }

    this.allSelected = selection;

    if (this.groupsSelected) {
      this.groupsSelected.emit(this.selectedGroups);
    }

  }

  onGroupSelect(group: GroupModel) {

    const index = this.selectedGroups.indexOf(group);
    if (index !== -1) {
      this.selectedGroups.splice(index, 1);
    } else {
      this.selectedGroups.push(group);
    }

    if (this.groupsSelected) {
      this.groupsSelected.emit(this.selectedGroups);
    }

  }

  hasSelectedGroup(group: GroupModel): boolean {

    return this.selectedGroups.includes(group);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onGroupClicked(group: GroupModel) {

    if (this.groupClickEnabled === true && this.groupClicked) {
      this.groupClicked.emit(group);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors());

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [this.selectionColumn, this.nameColumn, this.createdAtColumn];

  }

}

export class EVOGroupListSort {
  /**
   * The key to use for sorting
   */
  key = 'name';
  /**
   * Should be 1 or -1 for acending or descending
   */
  value = 1;
}
