import {Component, Input, OnInit} from '@angular/core';
import {AnswerModel} from '../../models/student/answer.model';
import {QuestionModel} from '../../models/questions/question.model';

@Component({
  selector: 'app-answer-container',
  templateUrl: './answer-container.component.html',
  styleUrls: ['./answer-container.component.css']
})
export class AnswerContainerComponent implements OnInit {

  CategoryTypes = QuestionModel.SubCategoryTypes;
  @Input() answer: AnswerModel;

  constructor() {
  }

  public static imgURLFraction(imageName: string): string {

    return `/assets/question-images/fraction-images/${imageName}.png`;

  }

  public static imgURLCorner(imageName: string): string {

    return `/assets/question-images/corner-images/${imageName}.png`;

  }

  public static imgURLPatternImage(image: string): string {

    return `/assets/item-images/${image}png`;

  }

  public static imgURLPerimeterShape(shape: string): string {

    return `/assets/question-images/perimeter-images/${shape}.png`;

  }

  public static imgURLAreaShape(shape: string): string {

    return `/assets/question-images/area-images/${shape}.png`;

  }

  ngOnInit() {
  }

  isTextBasedQuestionType(): boolean {

    const textBasedSubcats = [
      this.CategoryTypes.countNumbers,
      this.CategoryTypes.countShapes,
      this.CategoryTypes.countDiscrimination,
      this.CategoryTypes.countMultipleNumbers,
      this.CategoryTypes.countPairs,
      this.CategoryTypes.singleAddition,
      this.CategoryTypes.singleAdditionNeededAmount,
      this.CategoryTypes.doubleAdditionNeededAmount,
      this.CategoryTypes.doubleAddition,
      this.CategoryTypes.repeatedAddition,
      this.CategoryTypes.singleSubtraction,
      this.CategoryTypes.singleSubtractionNeededAmount,
      this.CategoryTypes.doubleSubtraction,
      this.CategoryTypes.singleMultiplication,
      this.CategoryTypes.singleDivision,
      this.CategoryTypes.shapeNameFromImage,
      this.CategoryTypes.unitHouse,
      this.CategoryTypes.sizeComparisonNumbers,
      this.CategoryTypes.sizeComparisonSymbols,
      this.CategoryTypes.sizeComparisonWords,
      this.CategoryTypes.numberPatternSingleFill,
      this.CategoryTypes.translation2D
    ];

    for (const subcat of textBasedSubcats) {
      if (this.answer.subCategory === subcat) {
        return true;
      }
    }

    return false;
  }

  isCorrectIconURL(): any {

    if (this.answer) {
      return this.answer.isCorrectIconURL();
    }

    return null;
  }

  answerCategoryIconURL(): string {

    if (this.answer) {
      return this.answer.answerCategoryIconURL();
    }

    return null;
  }

  answerString(): string {

    if (this.answer) {
      return this.answer.questionString();
    }
    return '';
  }

  imagePatternList(): string[] {

    if (this.answer && this.answer.subCategory === QuestionModel.SubCategoryTypes.imagePatternSingleFill) {

      const numberOfPatterns = Object.keys(this.answer.questionModel['parameters']).length;

      const patterns = [];
      for (let i = 0; i < numberOfPatterns; i++) {

        const paramName = QuestionModel.ParameterNameTypes.paramNameByIndex(i);
        const value = this.answer.questionModel['parameters'][paramName];

        if (value === null) {
          patterns.push(this.answer.answer);
        } else {
          patterns.push(value);
        }

      }

      return patterns;

    }

    return [];
  }

  proportionsPatternList(): string[] {

    if (this.answer && this.answer.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {

      const list = [];

      const imageTypeOne = this.answer.questionModel['meta']['imageTypeOne'];
      const imageTypeTwo = this.answer.questionModel['meta']['imageTypeTwo'];

      const numerator = this.answer.answer['one'];
      const denominator = this.answer.answer['two'];

      for (let i = 0; i < numerator; i++) {
        list.push(imageTypeOne);
      }

      for (let i = 0; i < denominator - numerator; i++) {
        list.push(imageTypeTwo);
      }

      return list;

    }

    return [];
  }

  imageURLForPatternImage(image: string): string {

    if (image) {
      return AnswerContainerComponent.imgURLPatternImage(image);
    }

    return null;

  }

  imageURLForFraction(imageName: string): string {

    if (this.answer && this.answer.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage) {

      return AnswerContainerComponent.imgURLFraction(imageName);

    }

    return null;

  }

  imageURLForCorner(imageName: string): string {

    return AnswerContainerComponent.imgURLCorner(imageName);

  }

  imageUrlForPerimeterShape(shape: string): string {

    return AnswerContainerComponent.imgURLPerimeterShape(shape);
  }

  imageTypeOne(): string {

    if (this.answer) {

      const meta = this.answer.questionModel['meta'];
      if (meta) {

        return meta['imageTypeOne'];

      }

    }

    return '';
  }

  wrongNumerator(): boolean {

    if (this.answer &&
      this.answer.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.answer.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {

      const missingFactor = this.answer.questionModel['missingFactor'];
      if (missingFactor !== 'denominator' && this.answer.fractionNumeratorAnswer() !== this.answer.fractionNumeratorExpectedAnswer()) {

        return true;
      }

    }

    return false;
  }

  correctNumerator(): boolean {

    if (this.answer &&
      this.answer.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.answer.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {

      const missingFactor = this.answer.questionModel['missingFactor'];
      if (missingFactor !== 'denominator' && this.answer.fractionNumeratorAnswer() === this.answer.fractionNumeratorExpectedAnswer()) {

        return true;
      }

    }

    return false;
  }

  wrongDenominator(): boolean {

    if (this.answer &&
      this.answer.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.answer.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {

      const missingFactor = this.answer.questionModel['missingFactor'];
      if (missingFactor !== 'numerator' && this.answer.fractionDenominatorAnswer() !== this.answer.fractionDenominatorExpectedAnswer()) {

        return true;
      }

    }

    return false;

  }

  correctDenominator(): boolean {

    if (this.answer &&
      this.answer.subCategory === QuestionModel.SubCategoryTypes.fractionSetupFromImage ||
      this.answer.subCategory === QuestionModel.SubCategoryTypes.proportionSetupFromImage) {

      const missingFactor = this.answer.questionModel['missingFactor'];
      if (missingFactor !== 'numerator' && this.answer.fractionDenominatorAnswer() === this.answer.fractionDenominatorExpectedAnswer()) {

        return true;
      }

    }

    return false;

  }

  answerCategory(): string {

    if (this.answer) {

      return 'categories.' + this.answer.category;
    }

    return null;
  }

  answerType(): string {

    if (this.answer) {
      return 'answerTypes.' + this.answer.answerType;
    }

    return null;
  }
}
