import { Component, OnInit } from '@angular/core';
import {StudentModel} from '../../../models/authentication/student.model';
import {CsvImportService} from '../../../services/reusable/csv-import.service';
import {Router} from '@angular/router';
import {SchoolAdminStudentService} from '../../../services/school/school-admin-student.service';
import {ModalService} from '../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {ErrorModel} from '../../../models/shared/error.model';
import {SchoolModel} from '../../../models/school/school.model';

@Component({
  selector: 'app-school-school-students-import',
  templateUrl: './school-admin-students-import.component.html',
  styleUrls: ['./school-admin-students-import.component.css']
})
export class SchoolAdminStudentsImportComponent implements OnInit {

  students: StudentModel[] = [];

  constructor(private csvSv: CsvImportService,
              private tSv: TranslateService,
              private modalSv: ModalService,
              private studentSv: SchoolAdminStudentService,
              private router: Router) { }

  ngOnInit() {
  }

  onFileChanged(event) {

    StudentModel.importStudentsViaCSV(event, this.csvSv, SchoolModel.getCurrent().country).subscribe((students) => {
      this.students = students;
    }, (err: ErrorModel) => {
      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), err.message);
    });

  }

  onCancelClicked() {
    this.router.navigate(['schooladmin', 'students']);
  }

  onCreateClicked() {

    this.studentSv.importStudents(this.students).subscribe((students) => {
      this.modalSv.showAlertModal(this.tSv.instant('reusable.success'),
        this.tSv.instant('schoolAdministration.students.studentsCreatedModalMessage'))
        .subscribe(() => {
        this.router.navigate(['schooladmin', 'students']);
      });
    }, (err) => {
      this.modalSv.showTranslatedErrorModal(ErrorModel.GetError(err.name, this.tSv));
    });

  }

  onDownloadTemplateClicked() {

    const a = document.createElement('a');
    a.href = 'assets/templates/student-Import-Template.csv';
    a.download = 'student-Import-Template.csv';
    // start download
    a.click();
    a.remove();

  }

}
