import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AdminSchoolUserService} from '../../../../../services/admin/admin-school-user.service';
import {UserModel} from '../../../../../models/authentication/user.model';
import {EVOSegmentedControlItem} from '../../../../../shared/evo-segmented-control/evo-segmented-control.component';
import {AdminSchoolService} from '../../../../../services/admin/admin-school.service';
import {SchoolModel} from '../../../../../models/school/school.model';

@Component({
  selector: 'app-evo-school-school-user-edit',
  templateUrl: './evo-admin-school-user-edit.component.html',
  styleUrls: ['./evo-admin-school-user-edit.component.css']
})
export class EvoAdminSchoolUserEditComponent implements OnInit {

  isEditMode = false;
  schoolId: string;
  userId: string;
  user: UserModel;
  school: SchoolModel;

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('students', 'schoolAdministration.students.title'),
    new EVOSegmentedControlItem('groups', 'schoolAdministration.groups.title'),
    new EVOSegmentedControlItem('settings', 'sidebar.settings')
  ];

  currentItem: EVOSegmentedControlItem = this.controls[0];

  constructor(private route: ActivatedRoute,
              private router: Router,
              private adminSchoolSv: AdminSchoolService,
              private schoolUserAdminService: AdminSchoolUserService) { }

  ngOnInit() {

    const urlParams = this.router.url.split('/');
    const page = urlParams[urlParams.length - 1];

    for (const item of this.controls) {
      if (item.key === page) {
        this.currentItem = item;
      }
    }

    this.route.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      this.userId = params.userId;

      if (this.userId) {
        this.schoolUserAdminService.fetchSchoolUserById(this.schoolId,  this.userId).subscribe((user) => {

          this.user = user;
          if (this.user.schoolId) {
            this.adminSchoolSv.fetchSchoolById(this.schoolId).subscribe((school) => {
              this.school = school;
            });
          }

        });

      }

    });

  }

  onPageChanged(item: EVOSegmentedControlItem) {

    this.currentItem = item;
    this.router.navigate(['admin', 'schools', this.schoolId, 'users', this.userId, item.key]);

  }

  onSchoolClicked() {

    this.router.navigate(['admin', 'schools', this.schoolId, 'detail', 'teachers']);

  }

}
