import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserModel} from '../../models/authentication/user.model';
import {EVOListViewColumn} from '../../models/UI/EVOListViewColumn.model';

@Component({
  selector: 'app-evo-teacher-list-view',
  templateUrl: './evo-teacher-list-view.component.html',
  styleUrls: ['./evo-teacher-list-view.component.css']
})
export class EvoTeacherListViewComponent implements OnInit {

  /**
   * Fixed or dynamic
   */
  @Input() heightType = 'fixed';

  /**
   * Used if height type is fixed.
   */
  @Input() height = '500px';

  @Input() teachers: UserModel[] = [];
  /**
   * Used to find the correct account type based on the currently selected school.
   */
  @Input() schoolId: string = null;

  allSelected = false;
  @Input() selectedTeachers: UserModel[] = [];

  @Input() hasMoreData = false;

  @Output() teachersSelected = new EventEmitter<UserModel[]>();
  @Output() fetchMore = new EventEmitter();

  @Input() sort = new EVOTeacherListSort();
  @Output() sortChanged = new EventEmitter<EVOTeacherListSort>();

  @Input() selectionColumn = EVOListViewColumn.xsFixedColumn('selection', false);
  @Input() nameColumn = EVOListViewColumn.largeGrowColumn('name');
  @Input() accountTypeColumn = EVOListViewColumn.smallFixedColumn('accountType');
  @Input() createdAtColumn = EVOListViewColumn.smallFixedColumn('createdAt');

  @Input() teacherClickEnabled = false;
  @Output() teacherClicked = new EventEmitter<UserModel>();

  @Input('selectionEnabled')
  set selectionEnabled(selection: boolean) {
    this.selectionColumn.isVisible = selection;
  }

  getSelectionEnabled(): boolean {
    return this.selectionColumn.isVisible;
  }

  constructor() { }

  ngOnInit() {
  }

  getContainerHeight() {

    if (this.heightType === 'fixed') {
      return this.height;
    }

    return 'auto';

  }

  getContentHeight() {

    if (this.heightType === 'fixed') {
      return 'calc(100% - 38px)';
    }

    return 'auto';

  }

  getYOverflow(): string {

    if (this.heightType === 'fixed') {
      return 'scroll';
    }

    return 'auto';
  }

  onAllSelected(selection: boolean) {

    if (selection) {
      this.selectedTeachers = this.teachers;
    } else {
      this.selectedTeachers = [];
    }

    this.allSelected = selection;

    if (this.teachersSelected) {
      this.teachersSelected.emit(this.selectedTeachers);
    }

  }

  onTeacherSelect(teacher: UserModel) {

    const index = this.selectedTeachers.indexOf(teacher);
    if (index !== -1) {
      this.selectedTeachers.splice(index, 1);
    } else {
      this.selectedTeachers.push(teacher);
    }

    if (this.teachersSelected) {
      this.teachersSelected.emit(this.selectedTeachers);
    }

  }

  hasSelectedTeacher(teacher: UserModel): boolean {

    return this.selectedTeachers.includes(teacher);

  }

  onFetchMoreData() {
    if (this.fetchMore) {
      this.fetchMore.emit();
    }
  }

  onNameLegendSortClicked() {

    if (this.sort.key !== 'name') {

      this.sort.key = 'name';
      this.sort.value = -1;

    } else {

      if (this.sort.value === 1) {
        this.sort.value = -1;
      } else {
        this.sort.value = 1;
      }

    }

    if (this.sortChanged) {
      this.sortChanged.emit(this.sort);
    }

  }

  onTeacherClicked(teacher: UserModel) {

    if (this.teacherClickEnabled === true && this.teacherClicked) {
      this.teacherClicked.emit(teacher);
    }

  }

  getSortArrowImgUrl(): string {

    if (this.sort.value === 1) {
      return './assets/icons/sort-up.svg';
    } else {
      return './assets/icons/sort-down.svg';
    }

  }

  calcWidth(size: string): string {

    return EVOListViewColumn.calcWidth(size, this.getColumnDescriptors())

  }

  getColumnDescriptors(): EVOListViewColumn[] {

    return [
      this.selectionColumn,
      this.nameColumn,
      this.accountTypeColumn,
      this.createdAtColumn
    ];

  }

}

export class EVOTeacherListSort {
  /**
   * The key to use for sorting
   */
  key = 'name';
  /**
   * Should be 1 or -1 for acending or descending
   */
  value = 1;
}

