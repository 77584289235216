import {Component, OnInit, QueryList, ViewChildren} from '@angular/core';
import {StudentModel} from '../../../models/authentication/student.model';
import {ActivatedRoute, Router} from '@angular/router';
import {StudentService} from '../../../services/student/student.service';
import {ModalService} from '../../../shared/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import {UserModel} from '../../../models/authentication/user.model';
import {ErrorModel} from '../../../models/shared/error.model';
import * as moment from 'moment';
import {SchoolGroupFilter, SchoolGroupsService} from '../../../services/school/school-groups.service';
import {GroupModel} from '../../../models/school/group.model';
import {EVOSegmentedControlItem} from '../../../shared/evo-segmented-control/evo-segmented-control.component';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';
import {SchoolModel} from '../../../models/school/school.model';
import {NumberExtensionModel} from '../../../models/extensions/number-extension.model';
import {QrCodeDownloadService} from '../../../services/user/qr-code-download.service';
import {StringExtensionModel} from '../../../models/extensions/string-extension.model';

@Component({
  selector: 'app-select-student',
  templateUrl: './select-student.component.html',
  styleUrls: ['./select-student.component.css']
})
export class SelectStudentComponent implements OnInit {

  @ViewChildren('qrcodes') qrcodes: QueryList<any>;

  public teacherStudent: StudentModel = null;
  public students: StudentModel[] = [];
  public groups: GroupModel[] = [];
  showList = [];

  hasMoreStudents = false;
  studentFilter = new StudentFilterModel();

  hasMoreGroups = false;
  groupFilter = new SchoolGroupFilter();

  groupQRCodeDownload: GroupModel = null;

  controls: EVOSegmentedControlItem[] = [
    new EVOSegmentedControlItem('groups', 'selectStudent.groups'),
    new EVOSegmentedControlItem('students', 'selectStudent.students')
  ];

  currentItem = this.controls[0];

  constructor(private router: Router,
              private route: ActivatedRoute,
              private studentSv: StudentService,
              private modalSv: ModalService,
              public tSv: TranslateService,
              private groupSv: SchoolGroupsService,
              private qrDownloadSv: QrCodeDownloadService) {
  }

  ngOnInit() {

    if (this.currentSchool() && UserModel.getCurrent().hasSchoolAccess()) {
      this.currentItem = this.controls[0];
      this.fetchGroups();
    } else {
      this.currentItem = this.controls[1];
      this.fetchStudents();
    }

    this.studentFilter.skip = 0;
    this.studentFilter.limit = 20;

    if (UserModel.getCurrent()) {

      if (this.tSv.currentLang !== UserModel.getCurrent().culture &&
        UserModel.getCurrent().culture !== null &&
        UserModel.getCurrent().culture !== undefined) {
        this.tSv.use(UserModel.getCurrent().culture);
      }

    }

  }

  getScrollBarWidth(): string {

    return NumberExtensionModel.getScrollBarWidth() + 'px';
  }

  getContentHeight(): string {

    if (this.isSchoolSelected()) {
      return 'calc(100% - 104px)';
    }

    return 'calc(100% - 62px)';
  }

  onStudentFilterChanged(value: string) {

    if (value && value !== '') {
      this.studentFilter.name = value;
    } else {
      this.studentFilter.name = null;
    }

    this.studentFilter.skip = 0;
    this.students = [];

    this.fetchStudents();

  }

  fetchStudents() {

    if (SchoolModel.getCurrent()) {
      this.studentFilter.schoolId = SchoolModel.getCurrent()._id;
    } else {
      this.studentFilter.schoolId = null;
      this.studentFilter.nonSchoolStudents = 1;
    }

    this.studentFilter.skip = this.students.length;
    this.studentSv.fetchStudents(this.studentFilter).subscribe((students) => {

      if (students.length % this.studentFilter.limit === 0 && students.length > 0) {
        this.hasMoreStudents = true;
      } else {
        this.hasMoreStudents = false;
      }

      for (const student of students) {
        if (this.students.filter((s) => s._id === student._id).length === 0) {
          this.students.push(student);
        }
      }

    }, (err: ErrorModel) => {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('reusable.unknownError'));

    });

  }

  fetchTeacherStudent() {

    this.studentSv.fetchTeacherStudent(SchoolModel.getCurrent()._id).subscribe((student) => {
      this.teacherStudent = student;
    });

  }

  onGroupFilterChanged(value: string) {

    if (value && value !== '') {
      this.groupFilter.name = value;
    } else {
      this.groupFilter.name = null;
    }

    this.groupFilter.skip = 0;
    this.groups = [];

    this.fetchGroups();

  }

  fetchGroups() {

    this.groupFilter.skip = this.groups.length;
    this.groupSv.fetchTeacherGroups(this.groupFilter).subscribe((groups) => {

      if (groups.length % this.groupFilter.limit === 0 && groups.length > 0) {
        this.hasMoreGroups = true;
      } else {
        this.hasMoreGroups = false;
      }

      for (const group of groups) {
        if (this.groups.filter((g) => g._id === group._id).length === 0) {
          this.groups.push(group);
        }
      }

    }, (err: ErrorModel) => {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('reusable.unknownError'));

    });

  }

  onQRCodeClicked(ev: MouseEvent, group: GroupModel) {

    ev.stopPropagation();
    this.groupQRCodeDownload = group;

    this.fetchAllStudentsForGroup(group, () => {
      setTimeout(() => {
        this.onDownloadQRCodes();
      }, 200);
    });


  }

  currentSchool(): SchoolModel {

    return SchoolModel.getCurrent();
  }

  isSchoolSelected() {

    if (SchoolModel.getCurrent()) {
      return true;
    }

    return false;

  }

  onAddStudentClicked() {

    this.router.navigate(['createstudent']);

  }

  onStudentClicked(s: StudentModel) {

    StudentModel.setCurrent(s);
    this.router.navigate(['platform', 'dash']);

  }

  onPageChanged(item: EVOSegmentedControlItem) {
    this.currentItem = item;

    if (this.currentItem.key === 'students') {
      this.studentFilter.skip = 0;
      this.students = [];
      this.fetchStudents();
      this.fetchTeacherStudent();
    } else if (this.currentItem.key === 'groups') {
      this.groupFilter.skip = 0;
      this.groups = [];
      this.fetchGroups();
    }

  }

  showStudentsOfGroup(index: number) {

    const group = this.groups[index];
    this.router.navigate(['groups', group._id]);

  }

  onGroupClicked(index: number) {

    const group = this.groups[index];
    GroupModel.setCurrent(group);
    this.router.navigate(['groupOverview', group._id, 'dash']);

  }

  searchStudentsForGroup(searchString: string, index: number) {

    if (searchString && searchString.length > 0) {
      this.groups[index].searchString = searchString;
    } else {
      this.groups[index].searchString = null;
    }

    this.groups[index].students = [];
    this.groups[index].hasMoreData = false;

    this.fetchStudentsForGroup(index);

  }

  fetchStudentsForGroup(index: number) {

    const filter = new StudentFilterModel();
    filter.limit = 50;
    filter.skip = this.groups[index].students.length;
    filter.name = this.groups[index].searchString;

    this.groupSv.fetchStudentForGroup(this.groups[index]._id, filter).subscribe((students) => {

      if (students.length === filter.limit) {
        this.groups[index].hasMoreData = true;
      } else {
        this.groups[index].hasMoreData = false;
      }

      this.groups[index].students = this.groups[index].students.concat(students);

    });

  }

  fetchAllStudentsForGroup(group: GroupModel, done: Function) {

    const filter = new StudentFilterModel();
    filter.limit = 50;
    filter.skip = group.students.length;

    this.groupSv.fetchStudentForGroup(group._id, filter).subscribe((students) => {

      group.students = group.students.concat(students);

      if (students.length === filter.limit) {
        this.fetchAllStudentsForGroup(group, done);
      } else {
        if (done) {
          done();
        }
      }

    });

  }

  convertBirthDate(birthDate: Date): string {

    return moment(birthDate).format('MM / YYYY');
  }

  // https://stackoverflow.com/questions/41484323/how-to-use-jspdf-with-angular-2
  onDownloadQRCodes() {

    this.qrDownloadSv.onDownloadQRCodes(this.qrcodes, this.groupQRCodeDownload.students, this.groupQRCodeDownload);

  }

  getTeacherStudentTwoInitials() {

    const teacherTestAccountString = this.tSv.instant('selectStudent.teacherTestAccountTitle');

    return StringExtensionModel.getInitials(teacherTestAccountString, 2);

  }

}
