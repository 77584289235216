import { Component, OnInit } from '@angular/core';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SchoolGroupsService} from '../../../services/school/school-groups.service';
import {StudentModel} from '../../../models/authentication/student.model';
import {ModalService} from '../../../shared/modal/modal.service';
import {EVOStudentListSort} from '../../../shared/evo-student-list-view/evo-student-list-view.component';

@Component({
  selector: 'app-group-students',
  templateUrl: './group-students.component.html',
  styleUrls: ['./group-students.component.css']
})
export class GroupStudentsComponent implements OnInit {

  studentFilter = new StudentFilterModel();
  groupId: string;
  students: StudentModel[] = [];
  hasMoreData = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private schoolGroupSv: SchoolGroupsService) { }
  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      this.groupId = params.groupId;

      this.studentFilter.limit = 20;
      this.fetchStudents();

    });

  }

  fetchStudents() {

    this.studentFilter.skip = this.students.length;

    this.schoolGroupSv.fetchStudentForGroup(this.groupId, this.studentFilter).subscribe((students) => {

      this.students = this.students.concat(students);

      if (students.length === this.studentFilter.limit) {
        this.hasMoreData = true;
      } else {
        this.hasMoreData = false;
      }

    });

  }

  onStudentClicked(student: StudentModel) {

    StudentModel.setCurrent(student);
    this.router.navigate(['platform', 'dash']);

  }

  onSortChanged(sort: EVOStudentListSort) {

    this.studentFilter.updateWithListSort(sort);
    this.students = [];

    this.fetchStudents();

  }

}
