import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {SchoolModel} from '../../models/school/school.model';
import {AuthService} from '../auth.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SchoolAdminService {

  constructor(private authSv: AuthService) { }

  fetchSchool(schoolId: string): Observable<SchoolModel> {

    return this.authSv.get('/schools/' + schoolId).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);
      return school;

    }));
  }

  fetchSchoolByLinkId(linkId: string): Observable<SchoolModel> {

    return this.authSv.get(`/schools/${linkId}/link`).pipe(map((response) => {

      const school = SchoolModel.generate(response.data);
      return school;

    }));

  }

}
