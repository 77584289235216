import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Route, Router} from '@angular/router';
import {AdminSchoolStudentService} from '../../../../../services/admin/admin-school-student.service';
import {StudentModel} from '../../../../../models/authentication/student.model';
import {ErrorModel} from '../../../../../models/shared/error.model';
import {ModalService} from '../../../../../shared/modal/modal.service';
import {AdminStudentService} from '../../../../../services/admin/admin-student.service';
import {AdminSchoolService} from '../../../../../services/admin/admin-school.service';
import {SchoolModel} from '../../../../../models/school/school.model';
import {AdminSchoolSelectionService} from '../../../../shared/admin-school-selection/admin-school-selection.service';

@Component({
  selector: 'app-evo-school-school-student-edit',
  templateUrl: './evo-admin-school-student-edit.component.html',
  styleUrls: ['./evo-admin-school-student-edit.component.css']
})
export class EvoAdminSchoolStudentEditComponent implements OnInit {

  isEditMode = false;
  schoolId: string;
  student = new StudentModel();
  SchoolTypes = StudentModel.SchoolTypesDropdownList;
  isUpdatingLocation = false;

  school: SchoolModel = null;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private studentSv: AdminStudentService,
              private schoolSv: AdminSchoolService,
              private schoolStudentService: AdminSchoolStudentService,
              private schoolSelectSv: AdminSchoolSelectionService) { }

  ngOnInit() {

    this.student.country = null;
    this.student.culture = null;

    this.route.params.subscribe((params: Params) => {

      this.schoolId = params.schoolId;
      if (params.studentId) {
        this.isEditMode = true;
        this.schoolStudentService.fetchSchoolStudentById(this.schoolId, params.studentId).subscribe((student) => {
          this.student = student;
        });
        this.schoolSv.fetchSchoolById(this.schoolId).subscribe((school) => {
          this.school = school;
        });
      }

    });

  }

  title(): string {

    if (this.isEditMode === true) {
      return 'Update student';
    }

    return 'Create student';
  }

  saveButtonTitle(): string {

    if (this.isEditMode === true) {
      return 'Save';
    }

    return 'Create';
  }

  onSaveClicked() {

    this.schoolStudentService.updateSchoolStudents(this.schoolId, [this.student]).subscribe((students) => {

      this.router.navigate(['admin', 'schools', this.schoolId, 'students', students[0]._id, 'edit']);

    });

  }

  onRepositionClicked() {

    this.student.evolytesProfile.unityLocation.x = 61.47;
    this.student.evolytesProfile.unityLocation.y = 47.61;
    this.student.evolytesProfile.unityMap = 'Island 1W';

    this.isUpdatingLocation = true;

    this.schoolStudentService.updateSchoolStudents(this.schoolId, [this.student]).subscribe((students) => {
      this.isUpdatingLocation = false;
      this.student = students[0];
      this.modalSv.showAlertModal('Success', 'student location repositioned');
    }, (err: ErrorModel) => {
      this.isUpdatingLocation = false;
      this.modalSv.showTranslatedErrorModal(err);
    });


  }

  onRemoveTokensClicked() {

    this.studentSv.removeTokens(this.student._id).subscribe((student) => {
      this.modalSv.showAlertModal('Success', 'Tokens removed successfully');
    }, (err: ErrorModel) => {
      this.modalSv.showTranslatedErrorModal(err);
    });

  }

  onChangeSchoolsClicked() {

    this.schoolSelectSv.show(this.school.country).subscribe((schools) => {

      if (schools && schools.length > 0) {
        const newSchool = schools[0];
        this.schoolStudentService.changeSchool(this.schoolId, this.student._id, newSchool._id).subscribe((newStudent) => {
          this.router.navigate(['schools', newSchool._id, 'students', this.student._id, 'edit']);
          this.modalSv.showAlertModal('Success', 'student has successfully changed schools.');
        }, (err: ErrorModel) => {
          this.modalSv.showTranslatedErrorModal(err);
        });
      }

    });

  }

}
