import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {StudentModel} from '../../../../../../models/authentication/student.model';
import {GroupModel, GroupQueryModel} from '../../../../../../models/school/group.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '../../../../../../shared/modal/modal.service';
import {SchoolAdminGroupService} from '../../../../../../services/school/school-admin-group.service';

@Component({
  selector: 'app-school-admin-group-add-existing-student',
  templateUrl: './school-admin-group-add-existing-student.component.html',
  styleUrls: ['./school-admin-group-add-existing-student.component.css']
})
export class SchoolAdminGroupAddExistingStudentComponent implements OnInit {

  groupId: string;

  students: StudentModel[] = [];
  filter = new GroupQueryModel();
  hasMoreData = false;

  notInGroupFilter = new GroupQueryModel();
  studentsNotInGroup: StudentModel[] = [];
  hasMoreStudentsNotInGroup = false;

  showStudentGroupHover = false;
  showSchoolGroupHover = false;

  // To know which container we are dragging from group or school
  dragFrom = 'None';

  showAddStudentsSmall = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private modalSv: ModalService,
              private tSv: TranslateService,
              private cd: ChangeDetectorRef,
              private groupSv: SchoolAdminGroupService) { }

  ngOnInit() {

    this.filter.sort = 1;
    this.filter.limit = 50;

    this.notInGroupFilter.sort = 1;
    this.notInGroupFilter.limit = 50;

    this.route.parent.params.subscribe((params: Params) => {
      this.groupId = params.groupId;
      this.fetchStudentsForGroup();
      this.fetchStudentsNotInGroup();
    });

  }

  // Get all the students in the group
  fetchStudentsForGroup() {

    this.filter.skip = this.students.length;

    this.groupSv.fetchGroupStudents(this.filter, this.groupId).subscribe((students) => {

      this.hasMoreData = students.length === this.filter.limit;

      for (const student of students) {
        if (this.students.filter((s) => s._id === student._id).length === 0) {
          this.students.push(student);
        }
      }

    });
  }

  fetchStudentsNotInGroup() {

    this.notInGroupFilter.skip = this.studentsNotInGroup.length;

    this.groupSv.fetchStudentsWithNoRelationshipToGroup(this.groupId, this.notInGroupFilter).subscribe((students) => {

      this.hasMoreStudentsNotInGroup = students.length === this.notInGroupFilter.limit;

      for (const s of students) {
        if (this.studentsNotInGroup.filter((cs) => cs._id === s._id).length === 0) {
          this.studentsNotInGroup.push(s);
        }
      }

    });
  }

  onSearchExistingStudents(studentName: string) {

    if (studentName === '' || !studentName) {
      this.filter.name = null;
      this.notInGroupFilter.name = null;
    } else {
      this.filter.name = studentName;
      this.notInGroupFilter.name = studentName;
    }

    this.students = [];
    this.studentsNotInGroup = [];

    this.fetchStudentsForGroup();
    this.fetchStudentsNotInGroup();

  }

  onFilterChanged(filter: GroupQueryModel) {

    this.filter.toAge = filter.toAge;
    this.filter.fromAge = filter.fromAge;

    this.notInGroupFilter.toAge = filter.toAge;
    this.notInGroupFilter.fromAge = filter.fromAge;

    this.students = [];
    this.studentsNotInGroup = [];

    this.fetchStudentsForGroup();
    this.fetchStudentsNotInGroup();

  }

  resetStudents() {

    this.students = [];
    this.filter.skip = 0;
    this.hasMoreData = false;

  }

  resetStudentsNotInGroup() {

    this.studentsNotInGroup = [];
    this.notInGroupFilter.skip = 0;
    this.hasMoreStudentsNotInGroup = false;

  }

  resetState() {

    this.students = [];
    this.filter.skip = 0;

    this.studentsNotInGroup = [];
    this.notInGroupFilter.skip = 0;

    this.hasMoreData = false;
    this.hasMoreStudentsNotInGroup = false;

  }

  /** Drag & Drop Logic */

  onDragStartFromGroupStudents(ev: DragEvent, student: StudentModel) {

    this.dragFrom = 'Group';
    ev.dataTransfer.setData('studentId', student._id);

  }

  onDragOverGroupStudents(ev: DragEvent) {

    if (this.dragFrom === 'School') {

      ev.preventDefault();
      this.showStudentGroupHover = true;

    }

  }

  onDragLeaveGroupStudents(ev: DragEvent) {

    if (this.dragFrom === 'School') {

      ev.preventDefault();
      this.showStudentGroupHover = false;

    }

  }

  onDropGroupStudents(ev: DragEvent) {

    if (this.dragFrom === 'School') {

      ev.preventDefault();

      this.showStudentGroupHover = false;
      const studentId = ev.dataTransfer.getData('studentId');

      this.groupSv.addStudentsToGroup(this.groupId, [studentId]).subscribe(() => {

        this.resetStudents();

        this.fetchStudentsForGroup();
        this.studentsNotInGroup = this.studentsNotInGroup.filter((s) => s._id !== studentId);

      });

    }

  }

  /** Dragging from school students **/

  onDragStartFromSchoolStudents(ev: DragEvent, student: StudentModel) {

    this.dragFrom = 'School';
    ev.dataTransfer.setData('studentId', student._id);

  }

  /** Dragging from group students **/

  onDragOverSchoolStudents(ev: DragEvent) {

    if (this.dragFrom === 'Group') {

      this.showSchoolGroupHover = true;
      ev.preventDefault();

    }

  }

  onDragLeaveSchoolStudents(ev: DragEvent) {

    if (this.dragFrom === 'Group') {

      this.showSchoolGroupHover = false;
      ev.preventDefault();

    }

  }

  onDropSchoolStudents(ev: DragEvent) {

    if (this.dragFrom === 'Group') {

      ev.preventDefault();

      this.showSchoolGroupHover = false;
      const studentId = ev.dataTransfer.getData('studentId');

      this.groupSv.removeStudentsFromGroup(this.groupId, [studentId]).subscribe(() => {

        this.resetStudentsNotInGroup();
        this.fetchStudentsNotInGroup();

        this.students = this.students.filter((s) => s._id !== studentId);

      });

    }

  }

  onCloseClicked() {
    this.router.navigate(['schooladmin', 'groups', this.groupId, 'students']);
  }

  onAddStudentsSM() {
    this.showAddStudentsSmall = true;
  }

  onStudentsAdded() {
    this.showAddStudentsSmall = false;
    this.resetState();
    this.fetchStudentsForGroup();
  }

}
