import {EVOStudentListSort} from '../../shared/evo-student-list-view/evo-student-list-view.component';

export class StudentFilterModel {

  skip = 0;
  limit = 100;

  name: string = undefined;
  schoolId: string = undefined;
  country: string = undefined;
  isActive: boolean = undefined;

  fromAge: number = undefined;
  toAge: number = undefined;

  // only for admins
  includeTeacherStudents: number = undefined;

  nonSchoolStudents: number = undefined;

  /**
    Sorts based on the name in descending or acending.
    By default it is ascending if this variable is undefined.
   */
  sort = 1;

  birthdateSort: number = undefined;

  /**
   * Additional sort based on createdAt date which can be added
   * to the sorting.
   */
  createdAtSort: number = undefined;

  updateWithListSort(sort: EVOStudentListSort) {

    this.sort = sort.name;
    this.birthdateSort = sort.birthdate;
    this.createdAtSort = sort.createdAt;

  }

}
