import {Component, OnInit, ViewChild} from '@angular/core';
import {StudentModel} from '../../../../models/authentication/student.model';
import {StudentService} from '../../../../services/student/student.service';
import {SessionsService} from '../../../../services/student/sessions.service';
import {AnswerService} from '../../../../services/student/answer.service';
import {NumberExtensionModel} from '../../../../models/extensions/number-extension.model';
import {CultureModel} from '../../../../models/localization/culture.model';
import {UserModel} from '../../../../models/authentication/user.model';
import {TranslateService} from '@ngx-translate/core';
import {ErrorModel} from '../../../../models/shared/error.model';
import {ModalService} from '../../../../shared/modal/modal.service';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {HelperModel} from '../../../../models/extensions/helper.model';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.component.html',
  styleUrls: ['./student-profile.component.css']
})
export class StudentProfileComponent implements OnInit {

  @ViewChild('qrimage', { static: false }) parent;

  playTime = 0;
  numAnswers = 0;
  loading = false;

  ProfileStates = {
    profile: 'profile',
    qrcode: 'qrcode'
  };

  constructor(private sessionsSv: SessionsService,
              private answerSv: AnswerService,
              private studentSv: StudentService,
              private modalSv: ModalService,
              public tSv: TranslateService) {
  }

  // To know whether we show QR Code or values are profile or qrcode
  state = this.ProfileStates.profile;

  ngOnInit() {

    this.sessionsSv.fetchSessionDuration(null, null).subscribe((time) => {
      this.playTime = time;
    });

    this.answerSv.fetchNumAnswers(null, null).subscribe((answers) => {

      if (answers) {
        this.numAnswers = answers;
      }

    });

  }

  getTimeSpentString(): string {

    return StringExtensionModel.timeStringDayHoursMinutes(this.playTime, this.tSv);

  }

  getNumAnswersString(): string {

    return NumberExtensionModel.delimiterSeparatedNumber(this.numAnswers, CultureModel.numberDelimiterForCulture(UserModel.getCurrent().culture));

  }

  currentStudent(): StudentModel {

    return StudentModel.getCurrent();
  }

  fullYear() {
    return this.currentStudent().birthDate.getFullYear();
  }

  onIdCopyClicked() {

    StringExtensionModel.copyStringToClipboard(this.currentStudent()._id);

    this.modalSv.showAlertModal(this.tSv.instant('reusable.success'), this.tSv.instant('reusable.copySuccess')).subscribe(() => {

    });

  }

  hasQRCode() {

    if (!StudentModel.getCurrent().qrCodePassword) {

      return false;
    }

    return true;

  }

  isQRCodeEnabled() {

    return StudentModel.getCurrent().isQRCodeAuthEnabled;
  }

  onQRCodeEnable() {

    const qrCodeStatus = StudentModel.getCurrent().isQRCodeAuthEnabled;
    StudentModel.getCurrent().isQRCodeAuthEnabled = !qrCodeStatus;

    this.studentSv.updateStudent(StudentModel.getCurrent()).subscribe((student) => {
      StudentModel.setCurrent(student);
      if (!student.qrCodePassword) {
        this.newCode();
      }
    }, (err: ErrorModel) => {
      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'), this.tSv.instant(''));
    });

  }

  newCode() {

    if (StudentModel.getCurrent()) {
      this.loading = true;
      this.studentSv.fetchQRCode().subscribe((student) => {
        StudentModel.setCurrent(student);
        this.loading = false;
      });
    }
  }

  saveAsImage(parent: any) {

    const parentElement = parent.el.nativeElement.querySelector('img').src;
    const blobData = HelperModel.convertBase64ToBlob(parentElement);

    const fileName = 'QRCode ' + StudentModel.getCurrent().fullName(this.tSv) + '.png';

    HelperModel.saveAsImage(fileName, blobData);

  }

}
