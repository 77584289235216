import { Component, OnInit } from '@angular/core';
import {AdminSkillGroupService} from '../../../../services/admin/admin-skill-group.service';
import {SkillGroupFilter, SkillGroupModel} from '../../../../models/edu-admin/skillGroup.model';
import {ActivatedRoute, Params} from '@angular/router';
import {AdminObjectiveService} from '../../../../services/admin/admin-objective.service';
import {ObjectiveModel} from '../../../../models/edu-admin/objective.model';
import {StringExtensionModel} from '../../../../models/extensions/string-extension.model';
import {
  AdminSkillGroupsSelectionListService
} from '../../../shared/admin-skill-groups-selection-list/admin-skill-groups-selection-list.service';
import {ModalService} from '../../../../shared/modal/modal.service';

@Component({
  selector: 'app-admin-objective-skill-groups',
  templateUrl: './admin-objective-skill-groups.component.html',
  styleUrls: ['./admin-objective-skill-groups.component.css']
})
export class AdminObjectiveSkillGroupsComponent implements OnInit {

  skillGroups: SkillGroupModel[] = [];
  filter = new SkillGroupFilter();
  obj: ObjectiveModel;

  skillGroupsToRemove: SkillGroupModel[] = [];

  constructor(private route: ActivatedRoute,
              private modalSv: ModalService,
              private objectiveSv: AdminObjectiveService,
              private skillGroupSelectionSv: AdminSkillGroupsSelectionListService,
              private skillGroupSv: AdminSkillGroupService) { }

  ngOnInit() {

    this.filter.limit = 20;

    this.route.parent.params.subscribe((params: Params) => {

      const objectiveId = params.objectiveId;

      this.objectiveSv.fetchObjectiveById(objectiveId).subscribe((objective) => {

        this.obj = objective;
        this.filter.skillGroupIds = StringExtensionModel.queryArrayString(objective.skillGroupIds);
        this.fetchSkillGroups();

      });


    });

  }

  fetchSkillGroups() {

    this.filter.skip = this.skillGroups.length;
    this.filter.skillGroupIds = StringExtensionModel.queryArrayString(this.obj.skillGroupIds);

    console.log('fetch skill groups: ' + JSON.stringify(this.obj.skillGroupIds, null, 4));

    if (this.filter.skillGroupIds.length > 0) {

      this.skillGroupSv.fetchSkillGroups(this.filter).subscribe((skillGroups) => {

        this.skillGroups = this.skillGroups.concat(skillGroups);

      });

    }

  }

  addSkillGroups() {

    const idsToExclude = StringExtensionModel.queryArrayString(this.obj.skillGroupIds);

    this.skillGroupSelectionSv.show(idsToExclude).subscribe((skillGroups) => {

      if (skillGroups) {
        for (const skillGroup of skillGroups) {
          this.obj.skillGroupIds.push(skillGroup._id);
          this.obj.skillGroups.push(skillGroup);
          this.skillGroups.push(skillGroup);
        }
      }

    });

  }

  removeSkillGroups() {

    this.modalSv.showChoiceModal('Warning', 'Are you sue you want to remove the skill groups? This action cannot be undone.').subscribe((response) => {

      if (response === true) {

        for (const skillGroup of this.skillGroupsToRemove) {

          let indexToRemove = -1;

          for (let i = 0; i < this.obj.skillGroupIds.length; i++) {

            const cSkillGroupId = this.obj.skillGroupIds[i];

            if (skillGroup._id === cSkillGroupId) {
              indexToRemove = i;
            }

          }

          this.obj.skillGroupIds.splice(indexToRemove);
          this.obj.skillGroups.splice(indexToRemove);

        }

        this.save();

      }

    });

  }

  save() {

    this.objectiveSv.updateObjective(this.obj).subscribe((objective) => {
      this.obj = objective;
      this.skillGroupsToRemove = [];
      this.modalSv.showAlertModal('Success', 'Objective successfully updated');
      this.skillGroups = [];
      this.fetchSkillGroups();
    });

  }

}
