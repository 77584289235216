import {Component, OnInit, ViewChild} from '@angular/core';
import {GroupModel} from '../../../models/school/group.model';
import {ActivatedRoute, Params} from '@angular/router';
import {SchoolGroupsService} from '../../../services/school/school-groups.service';
import {StudentModel} from '../../../models/authentication/student.model';
import {StudentFilterModel} from '../../../models/filters/student-filter.model';
import {ObjectiveModel} from '../../../models/edu-admin/objective.model';
import {SkillGroupModel} from '../../../models/edu-admin/skillGroup.model';
import {SkillGroupStructureModel} from '../../../models/student/skillGroupStatusModel';
import {forkJoin} from 'rxjs';

@Component({
  selector: 'app-group-dashboard',
  templateUrl: './group-dashboard.component.html',
  styleUrls: ['./group-dashboard.component.css']
})
export class GroupDashboardComponent implements OnInit {

  group: GroupModel;
  skillGroupStructure: SkillGroupStructureModel;
  skillGroups: SkillGroupModel[] = [];
  students: StudentModel[] = [];

  scrollOffset = 0;

  @ViewChild('scrollContainer', { static: true }) scrollContainer: HTMLDivElement;


  constructor(private route: ActivatedRoute,
              private schoolGroupSv: SchoolGroupsService) { }

  ngOnInit() {

    this.route.parent.params.subscribe((params: Params) => {

      const groupId = params.groupId;

      this.schoolGroupSv.fetchGroup(groupId).subscribe((g) => {
        this.group = g;
      });

      const studentFilter = new StudentFilterModel();
      studentFilter.limit = 100;
      const fetchStudents = this.schoolGroupSv.fetchStudentForGroup(groupId);

      const fetchGroupSkills = this.schoolGroupSv.fetchGroupSkills(groupId);

      forkJoin([fetchStudents, fetchGroupSkills]).subscribe((data) => {

        this.students = data[0];
        this.skillGroups = data[1].skillGroups.sort((s1, s2) => s1.order - s2.order);

        this.skillGroupStructure = new SkillGroupStructureModel(this.students, this.skillGroups, data[1].skills);

      });


    });

  }

  getNumStudents() {

    if (this.group) {
      return this.group.studentIds.length;
    }

  }

  scrollEvent(e) {

    this.scrollOffset = e.target.scrollTop;

  }


}
