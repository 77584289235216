import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {UserModel} from '../../../models/authentication/user.model';
import {ErrorModel} from '../../../models/shared/error.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ModalService} from '../../../shared/modal/modal.service';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {CultureModel} from '../../../models/localization/culture.model';
import {CountryModel} from '../../../models/localization/country.model';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {RecaptchaComponent} from 'ng-recaptcha';

@Component({
  selector: 'app-signup-secondary',
  templateUrl: './signup-secondary.component.html',
  styleUrls: ['./signup-secondary.component.css']
})
export class SignupSecondaryComponent implements OnInit {

  @ViewChild('captchaElem', { static: true })  captchaElem: RecaptchaComponent;

  queryParams: Params;
  user = new UserModel();
  captcha: string;
  country = CountryModel.getHomepageCountry();

  constructor(private router: Router,
              private route: ActivatedRoute,
              private modalSv: ModalService,
              private authSv: AuthService,
              private http: HttpClient,
              private tSv: TranslateService) { }

  ngOnInit() {

    this.route.queryParams.subscribe((params) => {
      this.queryParams = params;
    });

    this.user.culture = CultureModel.getHomepageCulture();
    this.user.country = CountryModel.getHomepageCountry();

  }

  onSignupClicked(form: NgForm) {

    const firstName = form.value.firstName;
    const lastName = form.value.lastName;
    const email = form.value.email;
    const password = form.value.password;
    const confirmPassword = form.value.confirmPassword;

    if (UserModel.passwordErraenous(password, confirmPassword, this.modalSv, this.tSv)) {

      return ;
    }

    if (this.user.country === null || this.user.country === undefined) {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('signup.selectCountry'),
        this.tSv.instant('reusable.done'));
      return ;

    }

    if (this.user.culture === null || this.user.culture === undefined)  {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('signup.selectLanguage'),
        this.tSv.instant('reusable.done'));
      return ;

    }

    if (!this.captcha) {

      this.modalSv.showErrorModal(this.tSv.instant('reusable.error'),
        this.tSv.instant('errors.CaptchaAuthFailed'));
      return;

    }

    this.user.firstName = firstName;
    this.user.lastName = lastName;
    this.user.email = email;

    if (this.user.email) {
      this.user.email = this.user.email.toLowerCase();
    }

    this.authSv.signUp(this.user, password, this.captcha).subscribe((response) => {

      this.tSv.use(response.user.culture);
      CultureModel.setHomepageCulture(response.user.culture);
      CountryModel.setHomepageCountry(response.user.country);
      this.router.navigate(['createstudent'], { queryParams: this.queryParams });

    }, (error: ErrorModel) => {
      this.captchaElem.reset();
      this.modalSv.showTranslatedErrorModal(error);
    });

  }

  captchaKey(): string {

    return environment.recaptchaV2Key;
  }

  captchaResponse(response: string) {

    this.captcha = response;

  }

  onEULAClicked() {

    this.router.navigate(['home', CultureModel.getHomepageCulture(), 'tc']);

  }

  onPrivacyPolicyClicked() {

    this.router.navigate(['home', CultureModel.getHomepageCulture(), 'privacy']);

  }

  onSignInClicked() {

    this.router.navigate(['home', CultureModel.getHomepageCulture(), 'signin']);

  }

}
